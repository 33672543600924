import {modalShow} from '../training_common';
import interact from 'interactjs';
import { getJSON } from 'jquery';

export default function no12Replace(){
    
   for( let i = 0 ; i < $(".question-wrapper-forjs").length ; i++){

    
    // target elements with the "draggable" class
    interact('.draggable.fb-no12-' + i)
        .draggable({
        // enable inertial throwing
        inertia: true,
        // keep the element within the area of it's parent
        modifiers: [
            interact.modifiers.restrictRect({
            restriction: '.question-wrapper-forjs',
            endOnly: true
            })
        ],
        // enable autoScroll
        autoScroll: false,

        listeners: {
            // call this function on every dragmove event
            move: dragMoveListener,
            end: dragMoveEndListener
        }
    });

    // enable draggables to be dropped into this
    interact('.dropzone.fb-no12-' + i).dropzone({
        // only accept elements matching this CSS selector
        accept: '.draggable.fb-no12-' + i,
        // Require a 75% element overlap for a drop to be possible
        overlap: 0.3,
    
        // listen for drop related events:
    
        ondropactivate: function (event) {
            //console.log("drop active");
        // add active dropzone feedback
        event.target.classList.add('drop-active')
        },
        ondragenter: function (event) {
            //console.log("drag enter");
        var draggableElement = event.relatedTarget
        var dropzoneElement = event.target
    
        // feedback the possibility of a drop
        dropzoneElement.classList.add('drop-target')
        draggableElement.classList.add('can-drop')
        //draggableElement.textContent = 'Dragged in'
        },
        ondragleave: function (event) {
            //console.log("drag leave");
        // remove the drop feedback style
        event.target.classList.remove('drop-target')
        event.relatedTarget.classList.remove('can-drop')
        //event.relatedTarget.textContent = 'Dragged out'
        },
        ondrop: function (event) {
            //console.log("drop");
            //console.log(event);
            const chrs = $(event.relatedTarget).text().split(' ') ;
            event.target.textContent = chrs[chrs.length -1]; 
            //interact('.draggable').reflow({name: 'drag', axis: 'xy'});
            //console.log( $("#inner-dropzone .yes-drop").text());
        //event.relatedTarget.textContent = 'Dropped'
        },
        ondropdeactivate: function (event) {
            //console.log("drop deavtive");
        // remove active dropzone feedback
        event.target.classList.remove('drop-active')
        event.target.classList.remove('drop-target')
        }
    })

    }

    $("#btn-attention-finish").on('click', function(){
        

        $(".dropzone").each( function(){
            $(this).removeClass('miss');

            if( $(this).text() != $(this).data("correct")){
                $(this).addClass('miss');
            }
        });

        if( $(".dropzone").hasClass("miss") ){
            modalShow("不合格", "不正");
        }
        else{
            modalShow("合格", "正解");
        }
        
    });


}

// event.pageX/Yはページ全体における終点
// event.x0はページ全体における始点


function dragMoveListener (event) {
    //console.log(event);
    var target = event.target

    // keep the dragged position in the data-x/data-y attributes
    var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
    var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy
    
    // translate the element
    target.style.webkitTransform =
      target.style.transform =
        'translate(' + x + 'px, ' + y + 'px)'
    
    // update the posiion attributes
    target.setAttribute('data-x', x)
    target.setAttribute('data-y', y)
}

function dragMoveEndListener (event) {
    var target = event.target
    // keep the dragged position in the data-x/data-y attributes
    var x = 0;
    var y = 0;
    
    // translate the element
    target.style.webkitTransform =
      target.style.transform =
        'translate(' + x + 'px, ' + y + 'px)'
    
    // update the posiion attributes
    target.setAttribute('data-x', x)
    target.setAttribute('data-y', y)
}

