import {redirectNextTraining, trainingInitialWindowSetting, imageSizing, waitImageLoadedForAfterAjax, virticalSpaceFitting} from './training_common';
import no1_no8Func from './attentions/no1_no8_replace';
import no9Drawing from './attentions/no9_drawing';
import no11Siki from './attentions/no11_siki';
import no12Replace from './attentions/no12_replace';

let isPerfect = 1 ; // 0/1: 不正解あり/全正解 

$(document).on('turbolinks:load', function(){
    if( $('#exercise-type').val() == "Attention"){
        
        let fontsize;
        const patternNo = $('#exercise-pattern_no').val();
        const previewMode = $('#preview-mode').val();
        const redirectUrl = $("#redirect-url").val();
        const workId = $("#work-id").val();
        const workLevel = $("#work-level").val();
        let loopInfo = JSON.parse($("#loop-info").val());
        const loopTotalCount = loopInfo.total ;
        let loopCounter = loopInfo.count;
        trainingInitialWindowSetting();


        if ( patternNo == "1"
        || patternNo == "2"
        || patternNo == "3"
        || patternNo == "4"
        || patternNo == "5"
        || patternNo == "6"
        || patternNo == "7"
        || patternNo == "8"){
        
        fontsize = attentionContentsFitting();
            no1_no8Func();
        }
        
        if( patternNo == "9"){
            // キャプチャ用のテーブルはモーダルを使うため、モーダルを表示
            // 非表示はno9Func()の中
            $(".c-modal").removeClass("c-hidden");

            // window.on.load中にhtml2canvasを実行したいため、以下の関数内で実行
            attentionContentsFitting();
        }

        if( patternNo == "11"){
            attentionContentsFitting();
            no11Siki();
        }

        if( patternNo == "12"){
            attentionContentsFitting();
            no12Replace();
        }

        // モーダルを非表示にした際に、ajax処理により、次の問題を描画する
        $("#trainingModal").on('hidden.bs.modal', function(){
            //if( $("#trainingModal .modal-title").html() == gon.modal_titles["合格"]) {
            if( $("#modal-title-key").val() == "合格") {
                if(loopCounter + 1 == loopTotalCount){
                    $.get("/save_training_history", {exercise_id: $('#exercise-id').val(), score: isPerfect}, redirectNextTraining);
                }
                else {
                    let workHistory = [];
                    if($("#current-work-id").length){
                        const currentWorkId = parseInt($("#current-work-id").val());
                        workHistory = JSON.parse($("#work-history").val());
                        workHistory.push(currentWorkId);
                        workHistory = $.uniqueSort(workHistory);
                        $("#work-history").val(JSON.stringify(workHistory));
                    }
                    
                    $.get("/ajax_attention_exercise", {work_id: workId, preview_mode: previewMode, pattern_no: patternNo, count: loopCounter + 1, work_level: workLevel, work_history: workHistory},function(){
                        
                        loopInfo = JSON.parse($("#loop-info").val());
                        loopCounter = loopInfo.count;

                        if( patternNo == "7" ){
                            // 読み込み画像のソースの配列を作成
                            const srcArry = [];
                            $(".attention-basis-fontsize img").each(function(){
                                srcArry.push($(this).attr("src"));
                            });
                            $(".attention-table img").each(function(){
                                srcArry.push($(this).attr("src"));
                            });
                            waitImageLoadedForAfterAjax(srcArry, attentionContentsFitting.bind(null, fontsize));
                        }
                        else if(patternNo == "8" ){
                            // 読み込み画像のソースの配列を作成
                            const srcArry = [];
                            $(".attention-basis-fontsize img").each(function(){
                                srcArry.push($(this).attr("src"));
                            });

                            waitImageLoadedForAfterAjax(srcArry, attentionContentsFitting.bind(null, fontsize));
                        }
                        else{
                            // ajaxレンダリング後に再度フォント調整
                            attentionContentsFitting(fontsize);
                        }
                        

                        
                    });
                }
            }
            // else if( $("#trainingModal .modal-title").html().indexOf("レベル") >= 0) {
            else if( $("#modal-title-key").val() == "レベル変更") {
                location.href = redirectUrl;
            }
            else if( $("#modal-title-key").val() != "ヒント表示"){
                isPerfect = 0;
            }
            
        });        
    }
});

// 画像とicon-fontの読み込みの後で実行する必要あり。つまりwindow.on.load。
// 画像とicon-font(変換テーブルなど)を使用しなければ、windos.on.loadは不要
function attentionContentsFitting(fontsize = -1){

    // 初回か二回目以降か否か
    let firstTimeFlag;

    const patternNo = $('#exercise-pattern_no').val();
    const $fbMainContentsHeight = $(".fb-main-contents-height");
    const $height20 = $(".height-20", $fbMainContentsHeight);
    const $attentionBasisFontsize = $(".attention-basis-fontsize") ;
    // フォントサイズ調整
    if(fontsize == -1){
        firstTimeFlag = true;
        fontsize = 50;
        
        while(1){
            fontsize -= 1;
            $attentionBasisFontsize.css("font-size", fontsize);
            // $(".google-icon-font").css("font-size", fontsize);
 
            if( patternNo == "9"){
                // console.log( $(".attention-basis-fontsize").width(), $(".height-20 .col-4").width(), fontsize);               
                if( $attentionBasisFontsize.width() < $("#display-figures", $height20).width()){
                    //console.log("fontsize2: ", fontsize);
                    //console.log( $(".attention-basis-fontsize").height(), $(".height-20").height(), fontsize);
                    break;
                }
                //console.log(fontsize);
                // 全部で何個あるよの表示のフォント
                $(".no9-visible").css("font-size", fontsize);
            }
            else if( patternNo == "11"){
                // 変換テーブルのフォントサイズ
                if( $attentionBasisFontsize.width() < $(".no11-transfer-table-area").width()){
                    
                    //console.log("fontsize2: ", fontsize);
                    //console.log( $(".attention-basis-fontsize").height(), $(".height-20").height(), fontsize);
                    break;
                }
            }
            else if( patternNo == "12"){
                
                fontsize -= 1;
                $(".google-icon-font").css("font-size", fontsize);
                $(".question-wrapper-forjs").css("font-size", fontsize);
                let allClear = true;
                //console.log("枠の長さ： " + $(".answer-sentense").width());
                //console.log("枠の高さ： " + $(".answer-sentense").height());
                $(".sentense-length").each(function(){
                    //console.log("文章の長さ： " + $(this).width());
                    //console.log("文章の高さ： " + $(this).height());
                    // 文章が折り返されても枠に入ってしまう場合があるため、枠の高さの90%以内に文章が治るように設定
                    if($(".answer-sentense").width() * 0.9 < $(this).width()){
                        allClear = false;
                        return false;
                    }
                });
                
                if(allClear){
                    //console.log("fontsize2: ", fontsize);
                    //console.log( $(".attention-basis-fontsize").height(), $(".height-20").height(), fontsize);
                    break;
                }
                
            } 
            else {
                // 変換テーブルのフォントサイズ
                if( $attentionBasisFontsize.width() < $height20.width()){
                    
                    //console.log("fontsize2: ", fontsize);
                    //console.log( $(".attention-basis-fontsize").height(), $(".height-20").height(), fontsize);
                    break;
                }
            }
        }

        //　変換テーブルをダミーから本番用にアップデート(初回のみ)
        const trueTable = JSON.parse($("#display-transfer-table").val());
        if( patternNo == "9")
            $attentionBasisFontsize.html(trueTable.join(" "));
        else
            $attentionBasisFontsize.html(trueTable.join(", "));

        
    }
    else{
        //　二回目以降は、本番用の変換テーブルがロードされている。
        firstTimeFlag = false;
    }

    // 変換テーブルのフォントサイズ設定
    $attentionBasisFontsize.css("font-size", fontsize);
    $(".google-icon-font").css("font-size", fontsize);


    // 表の大きさ調整
    // 求めたフォントサイズで教師テーブルのフォントサイズを変更
    const $attentionTable = $(".attention-table");
    $attentionTable.css("font-size", fontsize);

    const $height80 = $(".height-80", $fbMainContentsHeight);    
    if(patternNo == "9"){
        //　絶対に正方形にする
        if( $attentionTable.width() >= $height80.height() * 0.9 ){
            $attentionTable.width($height80.height() * 0.9);
            $attentionTable.height($height80.height() * 0.9);
        }
        else{
            $attentionTable.height($attentionTable.width());
        }
    }
    else{
        // 横長であれば、高さを調整して長方形のまま
        if( $attentionTable.width() > $height80.height() * 0.9){
            $attentionTable.height($height80.height() * 0.8 );
        }  
        // 縦長であれば、正方形にする。
        else{
            $attentionTable.height($attentionTable.width());
        }
    }
    


    // no7, no8の画像サイズ用の変数(画像のwidth, heightはselectのロード後でしか取得できない)
    const $attentionTableAnswer = $(".attention-table-answer");
    const ratio = $attentionTableAnswer.width() / $attentionTableAnswer.height();
    const tdWidth = $("td:first", $attentionTableAnswer).width() * 0.8 ;
    const tdHeight = $("td:first", $attentionTableAnswer).width() / ratio * 0.8;
    // no8でtdの高さを固定しなければ、不安定に動く
    $("td", $attentionTableAnswer).height($("td:first", $attentionTableAnswer).width() / ratio);
    
    
    // 二回目以降は初回の処理をトリガーして実行
    if(firstTimeFlag == false){
        $(window).trigger("load");
    }
    else{
        $(window).on("load", function(){

            if(patternNo == "9")no9Drawing();

            // no7, no8の変換テーブルの画像のサイズ変換
            // console.log($attentionBasisFontsize);
            // console.log(tdWidth, tdHeight);
            $("img", ".attention-basis-fontsize").each(function(){
                
                imageSizing($(this), tdWidth, tdHeight);
                // console.log($(this).width(), $(this).height());
                // $(this).width(tdWidth);
                // console.log($(this).width(), $(this).height());
                // if( $(this).height() > tdHeight ){
                //     const imgRatio = $(this).width() / $(this).height() 
                //     $(this).height(tdHeight);
                //     $(this).width( $(this).height() * imgRatio );
                // } 
            });
    
            // no7の元テーブルの画像のサイズ変換
            $("img", ".attention-table").each(function(){
                imageSizing($(this), tdWidth, tdHeight);
            });
    
    
            // bootstrap-selectの設定
            const $selectpicker = $(".selectpicker");
            $selectpicker.on('changed.bs.select', function(e){
                //console.log("changed!");
                
                const $dropdownToggleButton = $(e.target).next();
                // 選択された表示のフォントサイズ調整
                $(".google-icon-font", $dropdownToggleButton).css("font-size", fontsize);
                $(".filter-option", $dropdownToggleButton).css("font-size", fontsize);
                
                // no8の画像用
                const $selectedImage = $("img", $dropdownToggleButton);
                imageSizing($selectedImage, tdWidth, tdHeight);
            });

            $selectpicker.on('shown.bs.select', function(e){
                //console.log("shown");
                // 選択肢のフォントサイズ調整(resizedメンバを新設し、最初の一回のみ調整)
                if(e.target.resized === undefined){
                    e.target.resized = true;
        
                    const $dropdown = $(e.target).parent();
                    
                    $(".google-icon-font", $dropdown).css("font-size", fontsize);
                    $(".dropdown-menu", $dropdown).css("font-size", fontsize);
                
                
                    // no8の画像の選択肢
                    $(".dropdown-menu img", $dropdown).each(function(){
                        imageSizing($(this), tdWidth, tdHeight);
                    });
                }
            });

            let count = 0;
            $selectpicker.on('loaded.bs.select', function(e){
                //console.log("loaded");
                count++;
                const $dropdownToggleButton = $(e.target).next();
                // 初期表示でセンタリング
                $(".filter-option", $dropdownToggleButton).css("text-align", "center");
                // 初期選択表示のフォントサイズ
                $(".google-icon-font", $dropdownToggleButton).css("font-size", fontsize);

                const $dropdown = $(e.target).parent();
                // 選択肢のセンタリング
                $(".dropdown-menu", $dropdown).css("text-align", "center");
    
                
                if(count == $selectpicker.length){

                    if(patternNo == "11"){
                        // no11
                        // オペランドのフォンントサイズ
                        $(".awesome-operand").css("font-size", fontsize * 0.8);
                    
                        // no11 の式ごとの高さ調整
                        let spaceSize = 60;
                        virticalSpaceFitting($(".vertical-space"), $fbMainContentsHeight.height() * 0.9, spaceSize, $(".calc-contents"));
                        /*
                        while(1){
                            $(".vertical-space").css("margin-bottom", spaceSize);
                            if( $(".calc-contents").height() < $fbMainContentsHeight.height() * 0.9 ){
                                //console.log(spaceSize);
                                break;
                            }
                            spaceSize -= 1;
                        }
                        */
                    }

                }
                
                

            });
        });
    }
    return fontsize;
}
