import {trainingInitialWindowSetting} from './training_common'

$(document).on('turbolinks:load', function(){
    if($(".user-profile").length > 0){

        trainingInitialWindowSetting();

        $(".user-profile label").css("white-space", "nowrap");

    }
});