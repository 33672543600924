import {redirectNextTraining, trainingInitialWindowSetting, fontSizeFittingByHeight, fontSizeFittingByWidth} from './training_common'
import { pastSec, startTimer,autoFontAdjust } from './eyes/eye_common'

import no1Func from './eyes/no1_number_order'
import no2No3Func from './eyes/no2_no3_number_search'
import no4Func from './eyes/no4_shooting_start'
import no5Func from './eyes/no5_connect_line'
import no6Func from './eyes/no6_moving_pen'

// ミス判定があれば1以外の数値
let isPerfect = 1;

$(document).on('turbolinks:load', function(){
    if( $('#exercise-type').val() == "Eye"){
        
        const patternNo = $('#exercise-pattern_no').val();
        const level = $("#work-level").val();
        const redirectUrl = $("#redirect-url").val();

        trainingInitialWindowSetting();

        if ( patternNo == "6" ){
            isPerfect = -2; // スコアは-2で固定(レベルアップダウンなし)
            $(window).on("load", function(){
                no6Func();
            })
            
        }

        if ( patternNo == "5" ){
            const studyMode = 0
            no5Func(studyMode);
        }

        if ( patternNo == "4" ){
            tableTargetFitting();
            $(window).on("load", function(){
                no4Func();
            });
            
        }
        
        // 数の順番にクリックする課題
        if ( patternNo == "1" ){
            const targetNumbers = JSON.parse($("#target-numbers").val());
            // 最初のターゲットナンバーを表示
            $("#eye-table-target").text(targetNumbers[0]);
            // ターゲットのフォント調整
            tableTargetFitting();
            /* コンテンツのフォントサイズ調整 */
            autoFontAdjust(patternNo, level);
            no1Func(targetNumbers);
        }

        if ( patternNo == "2" || patternNo == "3"){
            const includedCount = parseInt($("#included-count").val());
            // ターゲットのフォント調整
            tableTargetFitting();
            /* コンテンツのフォントサイズ調整 */
            autoFontAdjust(patternNo, level);
            
            no2No3Func(includedCount);
        }

        // モーダルを非表示にした際に、ajax処理により、次の問題を描画する
        $("#trainingModal").on('hidden.bs.modal', function(){
            //if( $("#trainingModal .modal-title").html() == gon.modal_titles["合格"]) {
               // alert(isPerfect);
            if( $("#modal-title-key").val() == "合格") {
                $.get("/save_training_history", {exercise_id: $('#exercise-id').val(), score: pastSec == 0 ? isPerfect : pastSec}, redirectNextTraining);
            }
            //else if( $("#trainingModal .modal-title").html().indexOf("レベル") >= 0) {
            else if( $("#modal-title-key").val() == "レベル変更") {
                location.href = redirectUrl;
            }
            else if( $("#modal-title-key").val() != "ヒント表示"){
                if(pastSec == 0){
                    // 1-no5の線引き対策
                    if( $("#modal-title-key").val() != "加点") {
                        isPerfect = 0;
                    }
                }
                else{
                    // タイマーを途中から再開
                    startTimer(pastSec);
                }
            }
        });
    }

});

// ターゲットのフォント調整（css設定でもはみ出る場合のみ）
function tableTargetFitting(){
    if($("#eye-table-target").length > 0){
        const defaultFontSize = 50;
        
        let fontsize = fontSizeFittingByHeight($("#eye-table-target"), $(".fb-sub-contents-height .height-30").height(), 1, defaultFontSize);
        fontsize = fontSizeFittingByWidth($("#eye-table-target p"), $("#eye-table-target").parent().width() * 0.9, 0.5, fontsize);
        
        // タイマーのフォントも設定
        //$(".timer-text").css("font-size", fontsize/2);
        fontSizeFittingByHeight($(".timer-text"), $(".fb-sub-contents-height .height-30").height(), 0.5, fontsize/2);

    }
}

