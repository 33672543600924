// モーダル表示を自動消去する処理でタイマーを使う。
// ユーザ操作でモーダルを消した際にタイマーの初期化を行うために、
// グローバル変数としてtimerIDを取得し、動いているタイマーを唯一とする。
let timerId;

function modalShowAutoClose(isAutoClose = true)
{
    // 動いているタイマーがあれば、止める
    clearTimeout(timerId);
    // モーダルの自動消去が未登録であれば設定
    // 回線速度が低いとモーダル表示直後に2秒経ってしまうため、モーダル表示後から2秒を計測するように変更（ソースコード下のヒントモーダル も同様）
    var events = $._data($('#trainingModal').get(0), "events");
    //console.log(events);
    $("#trainingModal").modal("show");

    if(isAutoClose){
        
        if(events === undefined || events.shown === undefined){
            $("#trainingModal").on("shown.bs.modal", function(){
                // モーダルの自動消去処理。2秒表示したら消える。
                timerId = setTimeout(function(){
                    $("#trainingModal").modal("hide");
                }, 2000);
            });
        }
    }
    else{
        if(events === undefined) return;
        // モーダルの自動消去なし（あれば取り除く）    
        if(events.shown !== undefined){
            $("#trainingModal").off("shown.bs.modal", events.shown[0].handler);
        }
    }
}

export function redirectNextTraining(res){
        
        // レベルアップ、レベルダウンのモーダル表示
        if(res.level != "nothing"){

            $.get("/ajax_leciel_modal", {exercise_id: $('#exercise-id').val(), title_key: res.title_key, message_key: res.message_key, level: res.level}, function(){
                
                modalShowAutoClose();
                //$("#trainingModal").modal("show");

                // モーダルの自動消去なし（あれば取り除く）
                // var events = $._data($('#trainingModal').get(0), "events");
                // if(events.shown != undefined){
                //     $("#trainingModal").off("shown.bs.modal", events.shown[0].handler);
                // }
                // モーダルの自動消去が未登録であれば設定

            });
            
        }
        else{
            // 管理者の問題プレビュー完了時
            if(res.redirect_url == "")
                location.reload();
            // 生徒の問題完了時
            else
                location.href = res.redirect_url;
        }
}

export function modalShow(title_key, message_key){
    $.get("/ajax_leciel_modal", {exercise_id: $('#exercise-id').val(), title_key: title_key, message_key: message_key}, function(){
        modalShowAutoClose();

        /*
        // 動いているタイマーがあれば、止める
        clearTimeout(timerId);
    
        $("#trainingModal").modal("show");

        // モーダルの自動消去が未登録であれば設定
        // 回線速度が低いとモーダル表示直後に2秒経ってしまうため、モーダル表示後から2秒を計測するように変更（ソースコード下のヒントモーダル も同様）
        var events = $._data($('#trainingModal').get(0), "events");
        if(events.shown == undefined){
            $("#trainingModal").on("shown.bs.modal", function(){
                // モーダルの自動消去処理。2秒表示したら消える。
                timerId = setTimeout(function(){
                    $("#trainingModal").modal("hide");
                }, 2000);
            });
        }
        */

    });
    
    
    
}

// ajaxでhtmlを再レンダリングした際に、画像の読み込みを待つ関数
export function waitImageLoadedForAfterAjax(imageSrcArry, callback){
    let current;
    const imgNum = imageSrcArry.length;
    let count = 0;

    // ダミー画像を作成
    for (let i = 0; i < imgNum; i++) {
        const createImg = new Image(); // 画像の作成
        // 画像読み込み完了したとき
        createImg.onload = function () {
            count += 1;
        }
        // 画像読み込み失敗したとき
        createImg.onerror = function () {
            count += 1;
        }
        createImg.src = imageSrcArry[i]; // 画像にsrcを指定して読み込み開始
    }

    // ローディング処理
    const nowLoading = setInterval(function () {
        // 現在の読み込み具合のパーセントを取得
        current = Math.floor(count / imgNum * 100);
        // パーセント表示の書き換え
        //console.log(current)

        // 全て読み込んだ時
        if (count == imgNum) {
            // ローディングの終了
            clearInterval(nowLoading);
            //console.log(imgNum + ' image done.');
            
            callback();
            
        }
    }, 100);
}


// 画像のサイズ変更
export function imageSizing(imgObj, width, height){
    if(imgObj.length == 0) return false;
    //console.log(imgObj.width() + "x" + imgObj.height(), height);
    imgObj.width(width);
    if( imgObj.height() > height ){
        // console.log(imgObj.height(), height);
        const imgRatio = imgObj.width() / imgObj.height(); 
        imgObj.height(height);
        imgObj.width( imgObj.height() * imgRatio);
    }
}

export function trainingInitialWindowSetting(){

    if($(".fb-sub-contents-height").length > 0){

        /*
        // ブラウザバック時にロードしたJS特にセレクタ がクリアされてしまう問題がsafariで生じているため、
        // プロファイルの変更はトレーニング以外の特定のページでのみ実施可能なように制御
        $("#change-profile-link").on("click", function(){
            console.log($(".free-training").length);
            if($(".finish-training-area").length <= 0
                && $("#calendar").length <= 0
                && $(".user-profile").length <= 0
                && $(".free-training").length <= 0
                && $(".personal-history").length <= 0){
                //alert("ユーザ情報の変更はトレーニング終了後に実行してください。");
                //return false;

            }
        });
        */

        // ヘッダーのフォント調整(google-iconが原因っぽいけどwindow.on.loadでないと、正常に処理されないことがある。)
        let headerFontsize;
        if($(".progress-wrapper").length > 0){
            // 装飾付きのヘッダー表示
            $(window).on("load", function(){
                headerFontsize = 20;
                headerFontSetting(headerFontsize);
            });
        }
        else{
            // 装飾なしのヘッダー表示
            headerFontsize = 40;
            headerFontSetting(headerFontsize);
        }
        
        // 説明文のスペースを大きく使える課題は、説明文の領域を拡大する。
        if( $("#btn-confirm-date").length > 0
        || $("#calendar").length > 0
        || $(".user-profile").length > 0
        || $(".free-training").length > 0
        || $(".personal-history").length > 0
        || $('#exercise-type').val() == "Eye" && ( $('#exercise-pattern_no').val() == "5" || $('#exercise-pattern_no').val() == "6" ) 
        || $('#exercise-type').val() == "Attention" && $('#exercise-pattern_no').val() == "12" 
        || $('#exercise-type').val() == "Memory" && ( $('#exercise-pattern_no').val() == "3" || $('#exercise-pattern_no').val() == "4" || $('#exercise-pattern_no').val() == "5" || $('#exercise-pattern_no').val() == "10" )
        || $('#exercise-type').val() == "ReadWrite" && ( $('#exercise-pattern_no').val() == "4" || $('#exercise-pattern_no').val() == "5" )){
            $(".height-70").height( $(".fb-sub-contents-height").height());
            $(".height-30").height( 0 );

            //領域を狭めたことで行き場を失ったボタンを定位置に引き上げ
            if($(".exception-up-btn").length > 0){
                let originalTop = $(".exception-up-btn").offset().top;
                $(".exception-up-btn").offset({top: originalTop - $(".fb-sub-contents-height").height() * 0.3 });
            }
        }
        
        // 説明文エリアのフォントサイズ調整
        const defaultFontSize = 60;
        //console.log($("#instruction-area").height(), $(".fb-sub-contents-height .height-70").height());
        let fontsize = fontSizeFittingByHeight($("#instruction-area"), $(".fb-sub-contents-height .height-70").height(), 1, defaultFontSize);
        //console.log(fontsize);
        
        
    }
}

// ヘッダー部のフォントサイズ調整
function headerFontSetting(headerFontsize){
    
    let fontsize ;
    // ヘッダー部分のフォント調整
    
    // トレーニング
    if($(".progress-wrapper").length){
        // まず横幅を調整
        fontsize = fontSizeFittingByWidth($(".progress-wrapper"), $(".title-for-teacher").width(), 0.5, headerFontsize);
    
        // 次に高さを確認し、違反していれば調整
        fontsize = fontSizeFittingByHeight( $(".progress-wrapper"), $(".title-for-teacher").parent().height(), 0.5, fontsize, $(".title-for-teacher"));

        //google icon fontを調整
        $(".title-for-teacher .header-google-icon-font").css("font-size", fontsize * 1.5);
    
    // フリー演習を含むトレーニング以外の画面
    }else{
        // まず横幅を調整
        fontsize = fontSizeFittingByWidth($(".title-for-teacher"), $(".title-for-teacher").width(), 0.5, headerFontsize);
    
        // 次に高さを確認し、違反していれば調整(特にフリー時のヘッダーが問題)
        fontsize = fontSizeFittingByHeight( $(".title-for-teacher"), $(".fb-header-contents-height").height() * 0.8, 0.5, fontsize);
    }
    
    // ユーザボタンについて、ニックネームが長い場合にアイコンのフォントを調整
    fontSizeFittingByWidth($(".user-button a:first span"), $(".user-button a:first").width() * 0.9, 0.5);

}

// $textのフォントサイズを小さくしていき、areaWidthの範囲に収まれば、その際のfontsizeをreturnする
// resolutionは、フォントサイズをいくつずつ小さくするかを指定
// defaultFontSizeは、指定がなければ、$textのフォントサイズを取得する。
// $otherは、$textとは違う要素の幅で終了判定したい場合に使う
export function fontSizeFittingByWidth($text, areaWidth, resolution = 1, defaultFontSize = -1, $other = -1){
    if($text.length == 0 || areaWidth == 0 || $text.width() == 0 || resolution <= 0) return -1;
    
    let fontsize ;
    if(defaultFontSize == -1){
        fontsize = $text.css("font-size").slice(0,2);
    }
    else{
        fontsize = defaultFontSize;
        $text.css("font-size", fontsize);
    }

    const $observed = $other == -1 ? $text : $other;

    if( areaWidth < $observed.width() ){
        fontsize -= resolution;
        while(1){
            //console.log(fontsize, areaWidth, $text.width());
            $text.css("font-size", fontsize);
            if( areaWidth > $observed.width() ){
                break;
            }
            fontsize -= resolution;
            if(fontsize <= 0){
                break;
            }
        }
    }
    // console.log("by width");
    // console.log($text[0], fontsize)
    return fontsize;
}

// $textのフォントサイズを小さくしていき、areaHeightの範囲に収まれば、その際のfontsizeをreturnする
// resolutionは、フォントサイズをいくつずつ小さくするかを指定
// defaultFontSizeは、指定がなければ、$textのフォントサイズを取得する。
// $otherは、$textとは違う要素の高さで終了判定したい場合に使う
export function fontSizeFittingByHeight($text, areaHeight, resolution = 1, defaultFontSize = -1, $other = -1){
    if($text.length == 0 || areaHeight == 0 || $text.height() == 0 || resolution <= 0) return -1;

    let fontsize ;
    if(defaultFontSize == -1){
        fontsize = $text.css("font-size").slice(0,2);
    }
    else{
        fontsize = defaultFontSize;
        $text.css("font-size", fontsize);
    }

    const $observed = $other == -1 ? $text : $other;

    if( areaHeight < $observed.height() ){
        fontsize -= resolution;
        while(1){
            //console.log(fontsize);
            $text.css("font-size", fontsize);
            if( areaHeight > $observed.height() ){
                break;
            }
            fontsize -= resolution;
            if(fontsize <= 0){
                break;
            }
        }
    }

    // console.log("by height");
    // console.log($text[0], fontsize)
    return fontsize;
}

// $vSpaceを小さくしていき、要求する高さに収まれば、その際のspaceSize（マージンボトム）をreturnする。
// $otherは、$vSpaceとは違う要素の高さで終了判定したい場合に使う
export function virticalSpaceFitting($vSpace, desiredHeight, defaultSpaceSize, $other = -1){
    let spaceSize = defaultSpaceSize

    const $observed = $other == -1 ? $vSpace : $other;
    
    $vSpace.css("margin-bottom", spaceSize);
    if( $observed.height() > desiredHeight ){
        spaceSize -= 1;
        while(1){
            $vSpace.css("margin-bottom", spaceSize);
            if( $observed.height() < desiredHeight ){
                break;
            }
            spaceSize -= 1;
            if(spaceSize <= 0) break;
        }
    }
    return spaceSize;
}

// ユーザエージェントを判定
export let userAgent;

$(document).on('turbolinks:load', function(){

    // トレーニング、ログイン画面用の設定
    if($(".fb-sub-contents-height").length > 0 || $(".fb-login-flash-height").length > 0){
        // viewportを元に、各種ブラウザでの100vhを正確に算出
        const setFillHeight = function(){
            const vh = window.innerHeight * 0.01;  
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
        window.addEventListener('resize', setFillHeight);
        window.addEventListener('load', setFillHeight);
        
        setFillHeight();

        
        //ダブルタップの禁止
        document.addEventListener("dblclick", function(e){
            e.preventDefault();
            },
            { passive: false }
        );

        // chromeでスクロールを禁止(iOSでプルダウンのスクロールが不可になる不具合)
        /*
        document.addEventListener("touchmove", function(e){
            e.preventDefault();
            },
            { passive: false }
        );
        */

        // ピンチズームの禁止
        const touchHandler = function(event){
            if (event.touches.length > 1) {
              event.preventDefault();
            }
          };
          document.addEventListener('touchstart', touchHandler, {
            passive: false
        });

        // ログイン画面のアプリ名のフォント調整
        if( $(".fb-login-flash-height").length > 0 )
            fontSizeFittingByWidth( $(".app-title"), $(".app-title-area").width() * 0.9, 0.5);


        // フッター部のフォントサイズ調整
        fontSizeFittingByWidth($(".copyright p"), $(".copyright-area").width() * 0.9, 0.5);


        // ブラウザの戻るに対処(safariでjsのロードが消えてしまう)
        // 'javascript:history.back()'
        // https://qiita.com/dkdkd335/items/5749cd740d876a9b88d5
        //history.replaceState(null, null, null);
        // window.addEventListener('popstate', function(e) {
        //     alert('ブラウザバックを検知しました。');
        // });

        //alert($(window).height());

    }

    // ユーザエージェントの解析
    const useragent = window.navigator.userAgent.toLowerCase();
    /* ブラウザごとの条件分岐 */
    if(useragent.indexOf('edge') != -1) {
        userAgent = "edge";
    } else if (useragent.indexOf('chrome') != -1) {
        userAgent = "chrome"
    } else if (useragent.indexOf('safari') != -1) {
        userAgent = "safari"
    } else {
        userAgent = "other"
    };
    //alert(userAgent);

    
    /*
    if(this.location.pathname.indexOf("admin") == -1){
        //alert($(window).width() * window.devicePixelRatio + "x" + $(window).height() * window.devicePixelRatio);
        let width = Math.floor( $(window).width() * window.devicePixelRatio );
        let height = $(window).height();
        let currentDisplay = "現在の画面解像度は" + width + "x" + height + "です。";
        let minWidth = 1024;
        let minHeight = 700;
        let minDisplay = "画面解像度が" + minWidth + "x" + minHeight + "以上でアクセスしてください。";

        if( width <= height){
            alert("横長の画面でアクセスしてください。" + currentDisplay );
        }
        if( width < minWidth){
            alert("画面の横幅が不足しています。" +  minDisplay  + currentDisplay );
        }
        else if( height < minHeight){
            alert("画面の高さが不足しています。" +  minDisplay  + currentDisplay );
        }
    }
    */
    
    // デバッグ用（本日の履歴削除）
    $("#today-history").on('click', function(){
        var answer = confirm('本当に削除しますか？');
        if(answer){
            $.get("/delete_today_history", function(){
                location.reload();
            });
        }
    });

    // ヒントモーダルの表示
    $(".show-hint-modal").on('click', function(){
        $.get("/ajax_hint_modal", {hint_id: $(this).data("hint-id")}, function(){
            modalShowAutoClose(false);
            /*
            $("#trainingModal").modal("show");

            // モーダルの自動消去なし（あれば取り除く）
            // 回線速度が低いとモーダル表示直後に2秒経ってしまうため、モーダル表示後から2秒を計測するように変更
            var events = $._data($('#trainingModal').get(0), "events");
            if(events.shown != undefined){
                $("#trainingModal").off("shown.bs.modal", events.shown[0].handler);
            }
            */
        });
    });

    // パスボタンをクリックした際に、ajax処理により、次の問題を描画する
    $("#btn-skip").on('click', function(e){
        //console.log(e.currentTarget);
        //$.get("/save_training_history", {work_ids: [-1]}, function(){
            $.get("/save_training_history", {exercise_id: $('#exercise-id').val()}, function(){
            //location.reload();
            const redirectUrl = $("#redirect-url").val();
            //location.href = gon.redirect_url;
            location.href = redirectUrl;
        });
    });

    // フリー設問選択時にレベルを決める
    $(".jq-free-level-btn").on('click', function(){
        $.get("/ajax_free_modal", {title: $(this).data("title"), type: $(this).data("type") }, function(){
            $("#trainingModal").modal("show");
            
            $(".modal-footer button").on('click', function(){
                const level = $('input:radio[name="level"]:checked').val();
                const type = $('#type').val();
                const path = $('#path').val();

                window.location.href = path + '?type=' + type + '&level=' + level ;
            })
        });
    });

     // for test
     $("#test-add").on("click", function(){
        modalShow("加点", "継続");
    });
    $("#test-ok").on("click", function(){
        modalShow("合格", "正解");
    });
    $("#test-ng").on("click", function(){
        if( $('#exercise-type').val() == "Eye" && $('#exercise-pattern_no').val() == "5" ) modalShow("不正", "始点");
        else modalShow("不合格", "不正");

    });
});