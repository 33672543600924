$(document).on('turbolinks:load', function(){
    var arg  = new Object;
    url = location.search.substring(1).split('&');
 
    $.each(url, function(index, val){
        var k = val.split('=');
        arg[k[0]] = k[1];
    });
    
    // 20210915　iOSデバイスにおいてログイン時のgcodeが自動入力されるように修正
    if( arg.gcode !== undefined && "" == $('#user_group_code').val() ) {
        
        $('#user_group_code').val(arg.gcode);
        
        $('#user_login_name').trigger("focus");
        
    }
    
    
});