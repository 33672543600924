import {modalShow} from './../training_common'
//var history = new Array();
let undoLimit = 0;
let count = 0; //正しくなぞれた線の数

// 問題作成で使うときはadmin == 1
export default function no5Func(admin = 1){

    // キャンバス設定
    let canvas = new fabric.Canvas("eye-no5-canvas");
    canvas.isDrawingMode = true;

    // キャンバスのラッパーエリアの設定
    // ペンの太さ、色
    let canvasZone;
    if(admin == 1){
        canvas.freeDrawingBrush.width = 10;
        canvas.freeDrawingBrush.color = "silver";
        canvasZone = ".eye-main-border-admin";
    }
    else{
        canvas.freeDrawingBrush.width = 2.5;
        canvas.freeDrawingBrush.color = "black";
        canvasZone = ".eye-main-border";
    }

    // 右側の画像は、お手本データ作成時に並び順がランダムに決定されため、保存時の並び順が保存されていば読み出す。
    // 並び順なしは-1;
    let rightImgIdxArry = -1;

    // キャンバスのスケールを動的に設定する。
    let canvasScale = 1;
    // canvasのサイズフィット。ループでCanvasの高さを98%掛けて縮小してき、canvasZoneに収まるポイントを探す。
    //window.addEventListener('resize', resizeCanvas, false);
    function resizeCanvas() {

        let height = $(canvasZone).height();
        while(1){

            // 最小の画面サイズ1024x700におけるeye-main-borderの横幅x縦幅=960.625x400.625だったので、そのアスペクト比に合わせる。
            canvas.setHeight(height);
            canvas.setWidth(height * 960.625/400.625);

            if(canvas.width > $(canvasZone).width()){
                height *= 0.98;
            }
            else break;
        }
        canvas.renderAll();
        $(canvasZone).height(height);

        // 本番でキャンバスがセンタリングできないため、強制的に移動
        if($("#no5-canvas-wrapper").length){
            //console.log($("#eye-no5-canvas").width(), canvas.width)
            $("#no5-canvas-wrapper").offset({left: $("#no5-canvas-wrapper").offset().left + ($(".eye-main-border").width() - canvas.width)/2});
        }

        // 保存データがあれば、そこから、右側の画像の並び順の情報と、保存データ作成時のキャンパスの高さを抽出する。
        // 保存データ作成時のキャンパスの高さと、現在のキャンパスの高ささから比を求めて、保存データをスケールする。 
        const data = $("#work_pictures_attributes_0_general_data").val();
        if(data !== undefined && data != ''){
            let jsonData = JSON.parse(data);
            canvasScale = height/jsonData.height;
            rightImgIdxArry = jsonData.rightImgIdxArry;
        }
    }
    // キャンバスの位置がブレることがあるため、window.on.loadの後で実行したいが、
    // そうすると管理画面でwindow.on.loadが実行されず問題あり。
    // 初回ロード時はうまくいっているので、しばらく様子をみる。20220130
    resizeCanvas();

    // 保存データがあればロード
    if( $("#work_pictures_attributes_0_payload").val() != '' && JSON.parse($("#work_pictures_attributes_0_payload").val()).objects.length > 0 ){
        new Promise(function(resolve){
            canvas.loadFromJSON($("#work_pictures_attributes_0_payload").val(), canvas.renderAll.bind(canvas), function(json, obj){
                // ロードしたPathを今のキャンバスに合わせてスケール
                if(obj.type == "path"){
                    obj.scale(canvasScale).set({
                        top: obj.top * canvasScale,
                        left: obj.left * canvasScale
                    });
                }   
            }); 
            resolve("json load and path scale done!");
        }).then(function(val){
            //console.log(val);
            new Promise(function(resolve){
                if( $("#images_url").val() !== undefined) { 
                    imagesSet(canvas, rightImgIdxArry).then( val => {
                        rightImgIdxArry = val;
                        //console.log("クリア後：", rightImgIdxArry);
                    });
                }
                resolve("image load done!");
            }).then(function(val){
                //console.log(val);
                // なぞり判定処理
                if(admin != 1){
                    practiceJudge(canvas, canvasScale, $(canvasZone).height());
                }
            });
        });
        
    }
    // 保存データがなければ、画像のみを設定    
    else{
        if( $("#images_url").val() !== undefined) { 
            imagesSet(canvas, rightImgIdxArry).then( val => {
                rightImgIdxArry = val;
                //console.log("クリア後：", rightImgIdxArry);
            });
        }
        // 画像登録がなければ、キャンパスの描画モードをキャンセル
        else canvas.isDrawingMode = false;
    }

    // データ保存（管理画面のみ）
    $("#eyes-save").on('click', function(){
        saveData(canvas, rightImgIdxArry).then(function(val){
            //console.log(val);
        });
    });

    // クリア（管理画面のみ）
    $("#fabric-clear").on('click', function(){
        canvas.clear();
        if( $("#images_url").val() !== undefined) { 
            imagesSet(canvas, -1).then( val => {
                rightImgIdxArry = val;
                //console.log("クリア後：", rightImgIdxArry);
            });
        }
        
    });

    // undo（管理画面のみ）
    $("#fabric-undo").on('click', function(){
        if(canvas._objects.length > undoLimit){
            //history.push(canvas._objects.pop());
            // 画像と丸のオブジェクトは消さない
            $.each(canvas._objects.reverse(), function(idx, obj){
                if(obj.type == "path") {
                    canvas.remove(obj);
                    return false;
                }
            });
            canvas.renderAll();
        }
    });
}

// 保存データが大きいため、非同期処理で完了を待った後にsubmitする。
async function saveData(canvas, rightImgIdxArry){
    let resolve = await new Promise(function(resolve){
                    // 画像データ、丸オブジェクトは保存しない
                    canvas.forEachObject(function(obj) {
                        if(obj.type != "path") canvas.remove(obj);
                    });
                    
                    // Pathの情報と、キャンバスの高さ情報、右側の画像の並び順情報を保存
                    var json = JSON.stringify(canvas.renderAll());
                    $("#work_pictures_attributes_0_payload").val(json);
                    $("#work_pictures_attributes_0_general_data").val(
                        JSON.stringify({
                            height: $(".eye-main-border-admin").height(),
                            rightImgIdxArry: rightImgIdxArry
                        })
                    );
                    
                    resolve("save Done!");
                });
    return resolve;
}


// なぞり判定処理
// 基本アルゴリズム
// お手本のPathの座標を指定した分解能毎に抽出して、大雑把な矩形を作成する。
// その矩形の中を全てなぞって（通過して）いればOK。
function practiceJudge(canvas, canvasScale, canvasZoneHeight){

    const totalLine = $("#total-line").val();

    /* なぞり判定に用いるエリアを求める */ 
    // 見本の線を解析し、キャンバスの高さの1/20を上下左右のマージンとして増幅させた線をなぞり判定に使う。
    const geta = canvasZoneHeight/20;
    
    // Path分割の分解能：小さくすると細かく、大きくすると大雑把に分割。
    // 5であれば、Path情報を５個おきに解析する。
    const divideNum = 5;

    // 矩形設定用の変数
    let setting;

    canvas.forEachObject(function(obj) {
        if(obj.type != "path") return;
        
        // お手本からPathの座標情報をディープコピー
        const paths = JSON.parse(JSON.stringify(obj.path));
        // 不要な始点、終点の情報を捨てる
        paths.shift();
        paths.pop();

        // 分解能ごとに抽出したPath情報の座標情報を求める
        const lazyPaths = [];
        let i = 0;
        while(1){
            if( i % 2 == 1 ){
                lazyPaths.push( [paths[ (i - 1) * divideNum ][1], paths[ (i - 1) * divideNum ][2], paths[ i * divideNum ][3], paths[ i * divideNum ][4] ] ) ;
            }
            i++;
            if(paths.length <= i * divideNum){
                lazyPaths.push( [paths[ (i - 1) * divideNum ][1], paths[ (i - 1) * divideNum ][2], paths[ paths.length - 1 ][3], paths[ paths.length - 1 ][4] ] ) ;
                break;
            } 
        }

        // 分割したPath情報をキャンバスのスケールに合わせてアップデートし、各Rectが太るように座標に下駄を履かせる。
        const scaledLazyPaths = [];
        $.each(lazyPaths, function(idx, rect){
            let scaledRect = rect.map(x => x * canvasScale);
            if(scaledRect[0] <= scaledRect[2] && scaledRect[1] >= scaledRect[3])
                scaledLazyPaths.push( [ new fabric.Point(scaledRect[0] - geta, scaledRect[1] + geta), new fabric.Point(scaledRect[2] + geta, scaledRect[3] - geta)] );
            else if(scaledRect[0] <= scaledRect[2] && scaledRect[1] <= scaledRect[3])
                scaledLazyPaths.push( [ new fabric.Point(scaledRect[0] - geta, scaledRect[1] - geta), new fabric.Point(scaledRect[2] + geta, scaledRect[3] + geta)] );
            else if(scaledRect[0] >= scaledRect[2] && scaledRect[1] >= scaledRect[3])
                scaledLazyPaths.push( [ new fabric.Point(scaledRect[0] + geta, scaledRect[1] + geta), new fabric.Point(scaledRect[2] - geta, scaledRect[3] - geta)] );
            else if(scaledRect[0] >= scaledRect[2] && scaledRect[1] <= scaledRect[3])
                scaledLazyPaths.push( [ new fabric.Point(scaledRect[0] + geta, scaledRect[1] - geta), new fabric.Point(scaledRect[2] - geta, scaledRect[3] + geta)] );
        });

        const safetyRectArry = [];
        // Path情報を細かい分解能で分化すると、矩形が多すぎて冗長になるので、矩形通しが交差する場合は、矩形を省略する。その際に使う変数
        let previousRect = -1; 
        
        // 下駄を履かせて太らせたパスを矩形化
        $.each(scaledLazyPaths, function(idx, rect){   
            if(rect[0].x <= rect[1].x && rect[0].y >= rect[1].y)
                    setting = {
                        left: rect[0].x,
                        top: rect[1].y,
                        fill: 'red',
                        width: rect[1].x - rect[0].x,
                        height: rect[0].y - rect[1].y
                    }
            else if(rect[0].x <= rect[1].x && rect[0].y <= rect[1].y)
                    setting = {
                        left: rect[0].x,
                        top: rect[0].y,
                        fill: 'red',
                        width: rect[1].x - rect[0].x,
                        height: rect[1].y - rect[0].y
                    }
            else if(rect[0].x >= rect[1].x && rect[0].y >= rect[1].y)
                    setting = {
                        left: rect[1].x,
                        top: rect[1].y,
                        fill: 'red',
                        width: rect[0].x - rect[1].x,
                        height: rect[0].y - rect[1].y
                    }
            else if(rect[0].x >= rect[1].x && rect[0].y <= rect[1].y)
                    setting = {
                        left: rect[1].x,
                        top: rect[0].y,
                        fill: 'red',
                        width: rect[0].x - rect[1].x,
                        height: rect[1].y - rect[0].y
                    }
                    
            const safetyRect = new fabric.Rect(setting);

            // なぞり判定用のRectに判定専用オリジナル関数を付与
            // Rect内に指定Pathの座標が存在するか否かを判定
            safetyRect.isIncludedWithinCoords = function(coords){
                const x1 = this.lineCoords.tl.x ;
                const y1 = this.lineCoords.tl.y ;
                const x2 = this.lineCoords.br.x ;
                const y2 = this.lineCoords.br.y ;
                let isIncluded = false; 
                $.each(coords, function(idx, coord){
                    if( x1 <= coord.x && coord.x <= x2){
                        if( y1 <= coord.y && coord.y <= y2){
                            isIncluded = true;
                            return false;
                        }
                    }
                });
                return isIncluded;
            }
            
            // １つ前に生成した矩形と、今回生成した矩形とが交差する場合は、今回の矩形を登録しない。
            if(previousRect != -1){
                if( safetyRect.intersectsWithObject(previousRect) ) return;
            }
            
            safetyRectArry.push(safetyRect);
            //canvas.add(safetyRect);
            previousRect = safetyRect;
        });
        // お手本Pathオブジェクトにメンバ(生成した矩形オブジェクトの配列) を追加
        obj.safetyRectArry = safetyRectArry;
        // お手本Pathオブジェクトにメンバ(合格済の線か否か) を追加
        obj.isCompleted = false;
        
        // お手本の線の始点をRect化（必須）
        setting = {
            left: obj.path[0][1] * canvasScale - geta,
            top: obj.path[0][2] * canvasScale - geta,
            fill: 'rgba(0,0,0,0)',
            width:  obj.path[0][1] * canvasScale + geta - (obj.path[0][1] * canvasScale - geta),
            height: obj.path[0][2] * canvasScale + geta - (obj.path[0][2] * canvasScale - geta),
            selectable: false
        }
        obj.pathStartRect = new fabric.Rect(setting); 
        
        // お手本の線の終点をRect化して描画（必須）
        setting = {
            left: obj.path[obj.path.length - 1][1] * canvasScale - geta,
            top: obj.path[obj.path.length - 1][2] * canvasScale - geta,
            fill: 'rgba(0,0,0,0)',
            width:  obj.path[obj.path.length - 1][1] * canvasScale + geta - (obj.path[obj.path.length - 1][1] * canvasScale - geta),
            height: obj.path[obj.path.length - 1][2] * canvasScale + geta - (obj.path[obj.path.length - 1][2] * canvasScale - geta),
            selectable: false
        }
        obj.pathEndRect = new fabric.Rect(setting);
        
        // 後の始点、終点の判定メソッドで
        // 厳し目の判定関数：isContainedWithinObjectを使う場合は、canvasに描画が必要(透明化＆選択不可設定で描画)。
        // canvas.add(obj.pathEndRect);
        // canvas.add(obj.pathStartRect);
    });
    
    // モーダル表示中か否かのフラグ
    let modalShowed = false;

    // 生徒が書いた線の解析開始
    canvas.on('path:created', function(target){
        const targetPath = target.path;
        let isCollect = false;
        
        // 判定ターゲットの始点と終点を矩形化し、対応する正しい始点、終点になぞり線が到達しているか確認（下駄は半分に設定）
        setting = {
            left: targetPath.path[targetPath.path.length - 1][1] - geta/2,
            top: targetPath.path[targetPath.path.length - 1][2] - geta/2,
            fill: 'blue',
            width:  targetPath.path[targetPath.path.length - 1][1] + geta/2 - (targetPath.path[targetPath.path.length - 1][1] - geta/2),
            height: targetPath.path[targetPath.path.length - 1][2] + geta/2 - (targetPath.path[targetPath.path.length - 1][2] - geta/2),
        }
        const targetPathEndRect = new fabric.Rect(setting);
        
        setting = {
            left: targetPath.path[0][1] - geta/2,
            top: targetPath.path[0][2] - geta/2,
            fill: 'blue',
            width:  targetPath.path[0][1] + geta/2 - (targetPath.path[0][1] - geta/2),
            height: targetPath.path[0][2] + geta/2 - (targetPath.path[0][2] - geta/2),
        }
        const targetPathStartRect = new fabric.Rect(setting);
        
        // for debug
        // canvas.add(targetPathStartRect);
        // canvas.add(targetPathEndRect);

        canvas.forEachObject(function(obj) {
            if (obj.type != "path") return;
            if (obj.stroke != "silver") return;
            if (obj.isCompleted) return;
            
            // 始点、終点の判断を厳しくする場合はisContainedWithinObjectを使う。その場合、obj.pathEndRect, obj.pathStartRectのキャンバス描画が必須
            //if( targetPathStartRect.isContainedWithinObject(obj.pathStartRect) ){
            if( targetPathStartRect.intersectsWithObject(obj.pathStartRect) ){
                //console.log("始点OK", obj.top);

                //if( targetPathEndRect.isContainedWithinObject(obj.pathEndRect)){
                if( targetPathEndRect.intersectsWithObject(obj.pathEndRect)){
                    //console.log("終点OK", obj.top);

                    $.each(obj.safetyRectArry, function(idx, rect){
                        
                        // Fabricの組み込み関数ではターゲットコントロールの矩形の影響で所望の判定できない
                        //if (rect.intersectsWithObject(targetPath, true, true)){
                        
                        // 専用関数をrectに付与して、判定
                        // １つでも判定用の矩形を通らなければNG;
                        if (rect.isIncludedWithinCoords(fabric.util.getPathSegmentsInfo(targetPath.path))){
                            //console.log(idx + 1 + "/" + obj.safetyRectArry.length + ": " + obj.top);
                        }
                        else{
                            //console.log("なぞりNG", obj.top);
                            modalShowed = true;
                            modalShow("不正", "なぞり");
                            
                            return false;
                        }

                        if( idx + 1 == obj.safetyRectArry.length) {
                            isCollect = true;
                            obj.isCompleted = true;
                        }
                    });

                    
                    return false;
                }
                else{
                    modalShowed = true;
                    modalShow("不正", "終点");
                    //console.log("終点NG", obj.top);
                    return false;
                }
            }
        });

        if(isCollect == false){
            if(modalShowed == false ){
                modalShow("不正", "始点");
            }
        }
        else{
            count++ ;

            //if(gon.count == count)
            if(totalLine == count)
                modalShow("合格", "正解");
            else
                modalShow("加点", "継続");
        }
        //canvas.isDrawingMode = false;
    });

    // 正しくなぞれなかった場合は、モーダルが閉じた際に生徒が書いたPathを消去
    $("#trainingModal").on('hidden.bs.modal', function(){
        // 20240404 ヒラからのTELでヒント表示の際に生徒が描写した線が消える不具合を聞いた。
        // ヒントモーダル を非表示にした際には消す処理をスキップ
        if( $("#modal-title-key").val() != "加点" && $("#modal-title-key").val() != "ヒント表示") {
            canvas._objects.pop();
            canvas.renderAll();
            modalShowed = false;
        }
    });
}

// 画像をURLからダウンロード
async function imageDownload(images, leftImgIdxArry) {
    const promiseArry = [];
    for(let i = 0 ; i < leftImgIdxArry.length ; i++){
        const promiseTask = new Promise(function(resolve){
                                fabric.Image.fromURL(images[leftImgIdxArry[i]], function(oImg){
                                    resolve(oImg);
                                    // let oImgWidth = canvas.width * rateOfWidth;
                                    // let oImgHeight = oImgWidth/oImg.width * oImg.height;
                                    // resolve(oImgHeight);                    
                                });
                            });
        promiseArry.push(promiseTask);
    }
    return await Promise.all(promiseArry);
}

// 各画像の高さを調整して、きれいに収まるまでフィッティングを繰り返す。
async function imageFitting(rateOfWidth, canvas, images, leftImgIdxArry, rightImgIdxArry, offset){
    await imageDownload(images, leftImgIdxArry).then( val => {

        let oImgWidth;
        let oImgHeight;
        let sum;
        let tooBig;
        while(1){
            sum = 0;
            tooBig = false;
            $.each(val, function(idx, oImg){
                oImgWidth = canvas.width * rateOfWidth;
                oImgHeight = oImgWidth/oImg.width * oImg.height;
                
                // 単画像が均等割された領域よりも大きかったら、即座に次のフィッティング処理へ
                if(oImgHeight > offset){
                    tooBig = true;
                    return false;
                }
                sum += oImgHeight;
            });

            if(sum > canvas.height || tooBig){
                // 1%づつ縮小させる。
                rateOfWidth -= 0.01;
            }
            else break;
        }

        //console.log(rateOfWidth, sum, canvas.height);
        rightImgIdxArry = imagesAddToCanvas(rateOfWidth, canvas, val, images, leftImgIdxArry, rightImgIdxArry, offset);
        //console.log("非同期処理Done!");
    });

    //console.log("非同期処理の後に通過してる？", rightImgIdxArry);
    return rightImgIdxArry;
}

// キャンバスに画像を追加
function imagesAddToCanvas(rateOfWidth, canvas, oImgs, images, leftImgIdxArry, rightImgIdxArry, offset){

    //左側(サイズフィット時にダウンロードした画像を使う)
    $.each(leftImgIdxArry, function(idx, imgIdx){    
        const oImg = oImgs[imgIdx];
        const oImgWidth = canvas.width * rateOfWidth;
        const oImgHeight = oImgWidth/oImg.width * oImg.height
        oImg.scaleToWidth(oImgWidth).set({
            top: offset * idx + offset/2 - oImgHeight/2,
            selectable: false
        });
        canvas.add(oImg);

        const circle = new fabric.Circle({
            radius: canvas.height/40, // 半径：キャンパスの大きさの1/40を半径とする円
            top: offset * idx + offset/2 - canvas.height/40, // 画像の中心
            left: oImgWidth,
            selectable: false
        });
        canvas.add(circle);
        
    });
    
    //右側
    if(rightImgIdxArry == -1){
        shuffleArray(leftImgIdxArry);
        rightImgIdxArry = leftImgIdxArry;
    }
    
    // 新規に画像をダウンロード(イメージオブジェクトの使い回し（同じオブジェクトをキャンバスに追加）できなかった。)
    $.each(rightImgIdxArry, function(idx, imgIdx){
        fabric.Image.fromURL(images[imgIdx], function(oImg){
            const oImgWidth = canvas.width * rateOfWidth;
            const oImgHeight = oImgWidth/oImg.width * oImg.height
            oImg.scaleToWidth(oImgWidth).set({
                top: offset * idx + offset/2 - oImgHeight/2,
                left: canvas.width - oImgWidth,
                selectable: false
            });
            canvas.add(oImg);

            const circle = new fabric.Circle({
                radius: canvas.height/40, // 半径：キャンパスの大きさの1/40を半径とする円
                top: offset * idx + offset/2 - canvas.height/40, // 画像の中心
                left: canvas.width - oImgWidth - canvas.height/20, 
                selectable: false
            });
            canvas.add(circle);
        });
    });
    
    return rightImgIdxArry ;
} 

async function imagesSet(canvas, rightImgIdxArry){
    
    // 登録画像のURLを読み出して配列化
    const images = JSON.parse($("#images_url").val());
    const leftImgIdxArry = [...Array(images.length).keys()];
    
    // キャンバスにおける単画像あたりの割り当て高さ
    const offset = canvas.height/images.length ;

    // 画像の表示領域はキャンバス横幅の15%で調整。画像が治らなければ、少しづつ下げていく。
    // 画像はキャンバスの両サイドに表示されるため、30%が画像領域で、70%が描画領域
    const rateOfWidth = 0.15;
    const resolve = await imageFitting(rateOfWidth, canvas, images, leftImgIdxArry, rightImgIdxArry, offset);

    //console.log("非同期処理の後に通過している？パート２", resolve);
    return resolve ;
}

// 配列をシャッフル
function shuffleArray(inputArray){
    inputArray.sort(()=> Math.random() - 0.5);
}
