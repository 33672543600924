let timerIdMemoryCountDonwn;
let timerIdMemoryWaitStartButton;

export function countDown(time){
    const totalTime = time;
    const oldTime = Date.now();

    timerIdMemoryCountDonwn = setInterval(() => {
        const currentTime = Date.now();

        // 差分を求める
        const diff = currentTime - oldTime;

        const diffSec = totalTime - diff;
            
        //ミリ秒を整数に変換
        const remainSec = Math.ceil(diffSec / 1000);

        let text = "<ruby><rb>残</rb><rt>のこ</rt></ruby>り" + remainSec + "<ruby><rb>秒</rb><rt>びょう</rt></ruby>";

        // 0秒以下になったら
        if (diffSec <= 0) {
            clearInterval(timerIdMemoryCountDonwn);

            // タイマー終了の文言を表示する
            text = gon.question_text;//"どのイラストが<ruby><rb>表示</rb><rt>ひょうじ</rt><ruby>されていたかな？";
        }
        
        // 画面に表示する
        $('#countdown').html(text);
    });

    // ターボリンクスの仕様でページ遷移後にタイマーが残るため、ページ遷移前にタイマーを消去する設定
    $(document).on('turbolinks:before-cache turbolinks:before-render', function() {
        if(timerIdMemoryCountDonwn != null) clearTimeout(timerIdMemoryCountDonwn);
    });
}

// ネット接続が低速な環境のための設定
// 課題で使用する画像のロードが完了すると「はじめる」ボタンが活性化される
export function waitStartButtonActive(waitingObjs){
    if($("#memory-start").length <= 0) return;
    if(!$("#memory-start").hasClass("disabled")){
        $("#memory-start").addClass("disabled");
    }
    timerIdMemoryWaitStartButton = setInterval(function(){
        if(waitingObjs != null){
            let allOK = true;
            waitingObjs.each(function(){
                // 画像の横幅が10ピクセル以下の場合はロード不完全
                if( $(this).width() < 10 ){
                    allOK = false;
                    return;
                }
                //console.log($(this).width());
            });
            if(allOK){
                $("#memory-start").removeClass("disabled");
                clearInterval(timerIdMemoryWaitStartButton);
            }
            
        }}, 1000);
    
        // ターボリンクスの仕様でページ遷移後にタイマーが残るため、ページ遷移前にタイマーを消去する設定
    $(document).on('turbolinks:before-cache turbolinks:before-render', function() {
        if(timerIdMemoryWaitStartButton != null) clearTimeout(timerIdMemoryWaitStartButton);
    });
}
