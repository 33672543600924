import {modalShow} from './../training_common';
export default function no5Func(){
    $("#read_write-finish").on('click', function(){
        let allok = true;
        $("input:checkbox").each(function(){
            // console.log( $(this).prop('checked') );
            // console.log( $(this).data("correct") );

            //if($(this).data("correct") == true ){

            
                if( $(this).prop('checked') != $(this).data("correct")){
                    allok = false
                }
            //}

        });

        if(allok == false){
            modalShow("不合格", "不正");
        }
        else{
            modalShow("合格", "正解");
            gon.count++;
        }
        
    });        
}