import {modalShow, imageSizing, fontSizeFittingByWidth} from '../training_common';
import {countDown} from './memory_common';
import html2canvas from 'html2canvas';


// 各文のセットアップ状態管理
let setupStatus = 0; // 0/1/2/3/4 = 文1の選択肢画像ロード/文2の選択肢画像ロード/文3の選択肢画像ロード/設問文のフォントサイズ、ボトムマージンの調整/完了

export default function no5Whole(fontsize, workId){

    // 「はじめる」ボタンを画像ロードが終わるまで押せなくする設定
    waitAllImageLoadedInSelectOption();

    const disappearSecond = parseInt($("#disappear-second").val());
    
    // selectpickerのセットアップ
    selectpickerSetUp(fontsize);
    // selectの選択肢を画像化(すでに画像化済みの場合は、画像ロード)
    optionsToImage(workId);
    
    $("#memory-finish").toggle();
    // スタートボタンをクリック
    $("#memory-start").on('click', function(){

        // 記憶する文章を表示
        $(".c-modal").removeClass("c-hidden");
        
        // スタートボタンを非表示
        $(this).toggle();
        countDown(disappearSecond * 1000);
        
        // 指定秒数後に、見本画像の非表示と、選択肢画像の表示
        setTimeout(function(){
            $("#no5-question").css("visibility", "visible");

            // 覚える文の非表示
            $(".c-modal").addClass("c-hidden");
            $("#memory-finish").toggle();
        }, disappearSecond * 1000);

    });

    $("#memory-finish").on('click', function(){
        let allok = true;
        $(".no5-sentense select").each(function(){
            const $dropdown = $(this).parent();
            $dropdown.removeClass("miss");
            //console.log("val: "+  $(this).val() );
            //console.log("correct: " +  $(this).data("correct") );
            if( $(this).val() == -1 ){
                //console.log($(this).parent());
                $dropdown.addClass("miss");
                allok = false;
            }
            else{
                //console.log($(this).val(), $(this).attr("id").split("-")[2]);
                if( $(this).val() != $(this).attr("id").split("-")[2] ){
                    $dropdown.addClass("miss");
                    allok = false;
                }
            }
        });

        if(allok == false){
            modalShow("不合格", "不正");
        }
        else{
            modalShow("合格", "正解");
        }
    });
}

function waitAllImageLoadedInSelectOption(){
    if(!$("#memory-start").hasClass("disabled")){
        $("#memory-start").addClass("disabled");
    }
    const timerIdMemoryWaitStartButton = setInterval(function(){
        let ready = true;
        //　3つの文の全セレクトボックスの選択肢の画像ロードが完了するまでは「はじめる」ボタンが押せない
        if(setupStatus != 4) ready = false
        if(ready){
            $("#memory-start").removeClass("disabled");
            clearInterval(timerIdMemoryWaitStartButton);
        }
    }, 1000);
    // ターボリンクスの仕様でページ遷移後にタイマーが残るため、ページ遷移前にタイマーを消去する設定
    $(document).on('turbolinks:before-cache turbolinks:before-render', function() {
        if(timerIdMemoryWaitStartButton != null) clearTimeout(timerIdMemoryWaitStartButton);
    });
}

// 文１〜３における画像化したURL配列（let必須）
let options = [];
async function optionsToImage(workId){

    // 画像化済みであれば、既存のURL配列を読み込んで終了
    if( $(".work-area").length <= 0 ){
        options = JSON.parse($("#image-urls").val());
        //console.log(options);
        return;
    }

    // 未画像化であれば、選択肢を画像化するために表示
    $("#no5-question").css("visibility", "visible");

    // 文１、２、３それぞれについて、画像化処理
    await toImage(workId, 0)
    await toImage(workId, 1)
    await toImage(workId, 2).then(function(val){
        //console.log(options);

        // 画像化が終了すると非表示化
        $("#no5-question").css("visibility", "hidden");
        $(".work-area").hide();

        // 選択肢に画像をロードし、プルダウンボックスの幅を調整するために、各プルダウンをクリックする。
        $(".sentense-1 button:first").trigger("click");
        // 回答文の折り返しを禁止（最初から折り返しを禁止すると文章を覚える画面からはみ出た文が裏から見えてしまうため）
        $(".no5-sentense").css("white-space", "nowrap");
    });
}

// 各文における画像化したURL配列（let必須）
let subArry;
function toImageMain(workId, maxWidth, maxHeight, optionArry, index, callback){
    if(index == optionArry.length) {
        options.push(subArry);
        callback();
        return;
    }

    let elm = optionArry[index][0];
    let sentenseNo = optionArry[index][1];
    let optionNo = optionArry[index][2];
    let width = optionArry[index][3];

    console.log(`${sentenseNo} - ${optionNo} start`);
    html2canvas(elm, {width: maxWidth, height: maxHeight, x:  - (maxWidth - width)/2 }).then(function(canvas){
        const image = canvas.toDataURL("image/png");
        
        subArry.push(image);
        $.post("/ajax_memory_5_save_option_images", {work_id: workId, sentense_no: sentenseNo, option_no: optionNo, image: JSON.stringify(image) }, function(){
            //console.log(`${sentenseNo} - ${optionNo} done1`);
            toImageMain(workId, maxWidth, maxHeight, optionArry, index + 1, callback);
        });
    });
}

function toImage(workId, sentense){

    // １文について、選択肢の最大幅、最大高さを求め、その幅高で、全ての選択肢を画像化する
    let maxWidth = -1;
    let maxHeight = -1;
    subArry = [];
    const optionArry = [];
    $(`.option-sentense-${sentense}`).each(function(){
        $("p", this).each(function(){
            const elm = $(this)[0]
            const sentenseNo = $(this).attr("id").split("-")[2];
            const optionNo = $(this).attr("id").split("-")[3];
            optionArry.push([elm, sentenseNo, optionNo, $(this).width()]);

            if( $(this).width() > maxWidth ){
                maxWidth = $(this).width();
            }
            if( $(this).height() > maxHeight ){
                maxHeight = $(this).height();
            }
        });
    });

    if(maxWidth == 0 || maxHeight == 0) return;
    
    return new Promise(function(resolve){
        toImageMain(workId, maxWidth, maxHeight, optionArry, 0, function(){
            resolve();
        });
    });
    

}

function selectpickerSetUp(fontsize){
    // bootstrap-selectの設定
    const $selectPicker = $(".selectpicker");

    let imageWidth;
    let imageHeight;
    //console.log(imageWidth, imageHeight);
    $selectPicker.on('changed.bs.select', function(e){
        
        // 選択された表示の画像サイズ調整
        const selectorId = e.target.id.split("-")[1];
        const button = $(e.target).next();
        // 選択肢のランダムに対応
        const optionId = Number(button.next().children().attr("aria-activedescendant").split("-")[3]) - 1;
        if(optionId != "remove"){
            const base64Data = options[selectorId - 1][optionId];
            const createImg = new Image();
            createImg.src = base64Data;
            createImg.onload = function(){
                $(".filter-option-inner-inner", button).html(createImg);
                imageSizing($("img", button), imageWidth, imageHeight);
            };
        }
        // 選択されたgoogle-icon-fontのサイズ調整
        else{
            let googleIcon = $("span", $(e.target).next());
            googleIcon.css("font-size", fontsize);
        }
        
    });

    $selectPicker.on('show.bs.select', function(e){
        //console.log("select show!");
        // 選択肢のフォントサイズ調整(resizedメンバを新設し、最初の一回のみ調整)
        if(e.target.resized === undefined){
            
            e.target.resized = true;

            const $dropdown = $(e.target).parent();
        
            $(".google-icon-font", $dropdown).css("font-size", fontsize);
            
            const sentenseId = e.target.id.split("-")[1];
            const selectorId = $(".dropdown-menu div", $dropdown).attr("id").split("-")[2];
            
            imageWidth = $(`.dropdown.sentense-${sentenseId}`).width() * 0.8;
            imageHeight = $(`.dropdown.sentense-${sentenseId}`).height() * 0.8;
            //console.log(imageWidth, imageHeight);
            
            
            // $(".dropdown-menu", $dropdown).css("font-size", fontsize);
            let count = 0;
            $.each(options[sentenseId - 1], function(idx, val){
                const optionSelector = `#bs-select-${selectorId}-${idx + 1} span`;
                //$(optionSelector).html(`<img src=${val} />`);
                //console.log(val);
                loadImage(val).then(function(res){
                    $(optionSelector).html(res);
                    // console.log($(res).width(), $(res).height());
                    //console.log($("img", optionSelector).width(), $("img", optionSelector).height());
                    imageSizing($("img", optionSelector), imageWidth, imageHeight);
                    count++;
                    //console.log( count, setupStatus );
                    if(count == options[sentenseId - 1].length && setupStatus < 3){
                        //console.log( optionSelector, count );
                        let width = $("img", optionSelector).width() * 2;
                        if(width < 110) width = 110; 
                        $(`.sentense-${sentenseId}.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn)`).css("width", width );
                        setupStatus++;
                        $(`.sentense-${sentenseId} button:first`).trigger("click");
                        
                    }
                    
                });
            });            
        }
    });

    $selectPicker.on('hidden.bs.select', function(e){
        // console.log("select hidden!");
        //console.log(setupStatus);

        if( setupStatus == 1){
            $(".sentense-2 button:first").trigger("click");
        }
        else if(setupStatus == 2){
            $(".sentense-3 button:first").trigger("click");
        }
        else if(setupStatus == 3){
            let maxWidth = [null, -1];
            $(".no5-sentense").each(function(){
                if($(this).width() > maxWidth[1]){
                    maxWidth[1] = $(this).width();
                    maxWidth[0] = $(this);
                }
            });
            //console.log(maxWidth[0], maxWidth[1], $("#no5-question").width());
            let fontsize = fontSizeFittingByWidth(maxWidth[0], $("#no5-question").width(), 1, maxWidth[0].css("font-size").slice(0, -2));
            $(".no5-sentense").css("font-size", fontsize);
            setupStatus++;
        }
    });
    
    let pickerSetupCount = 0;
    $selectPicker.on('loaded.bs.select', function(e){
        //console.log("select loaded!");
        pickerSetupCount++;
        const $dropdownToggleButton = $(e.target).next();
        // 初期表示でセンタリング
        $(".filter-option", $dropdownToggleButton).css("text-align", "center");
        // 初期選択表示のフォントサイズ
        $(".google-icon-font", $dropdownToggleButton).css("font-size", fontsize);

        const $dropdown = $(e.target).parent();
        // 選択肢のセンタリング
        $(".dropdown-menu", $dropdown).css("text-align", "center");

        // 選択肢の画像化が完了していて（ワークエリアがなくて）、全てのpickerのセットアップが完了した場合に、画像のロードをバックグラウンドで実行
        if(pickerSetupCount == $selectPicker.length && $(".work-area").length <= 0 ){
            // 選択肢に画像をロードし、プルダウンボックスの幅を調整するために、各プルダウンをクリックする。
            $(".sentense-1 button:first").trigger("click");
            // 回答文の折り返しを禁止（最初から折り返しを禁止すると文章を覚える画面からはみ出た文が裏から見えてしまうため）
            $(".no5-sentense").css("white-space", "nowrap");
        }
        

    });


    // 関数化
    function loadImage(src) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = (e) => reject(e);
            img.src = src;
        });
    }
}