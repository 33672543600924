import {trainingInitialWindowSetting} from './training_common'

$(document).on('turbolinks:load', function(){
    if($(".personal-history").length > 0){

        trainingInitialWindowSetting();

        let fontsize = 60;

        $(".personal-history table th").css("white-space", "nowrap");
        $(".personal-history table td").css("white-space", "nowrap");

        $(".personal-history table").css("font-size", fontsize);
        if($(".personal-history table").height() > $(".personal-history").height()){
            fontsize -= 1;
            while(1){
                $(".personal-history table").css("font-size", fontsize);
                if($(".personal-history table").height() < $(".personal-history").height()){
                    break;
                }
                fontsize -= 1;
            }
        }

        if($(".personal-history table").width() > $(".personal-history").width()){
            fontsize -= 1;
            while(1){
                $(".personal-history table").css("font-size", fontsize);
                if($(".personal-history table").width() < $(".personal-history").width()){
                    break;
                }
                fontsize -= 1;
            }
        }

        $(".training-date").css("font-size", fontsize);

    }
});