import {modalShow, trainingInitialWindowSetting, imageSizing} from './training_common'

$(document).on('turbolinks:load', function(){
    if($("#btn-confirm-date").length > 0){

        // パスボタンを非表示
        //$("#btn-skip").hide();

        trainingInitialWindowSetting();
        $(window).on("load", function(){
            $(".tottori-images").each(function(){
                imageSizing($(this), $(".confirm-date").width()/10, $(".confirm-date").height()/5);
            });
        });
        
        modalShow("トレーニング開始", "");

        const fontsize = $("#instruction-area").css("font-size") ;
        $(".confirm-date-table").css("font-size", fontsize);
        
        $("#btn-confirm-date").on('click', function(){
            var month = $("#month").val();
            var day = $("#day").val();
            var today = new Date();
            var answer = new Date(today.getFullYear(), month - 1, day);
            if(today.getMonth() == answer.getMonth() && today.getDay() == answer.getDay()){
                modalShow("合格", "正解");
            }
            else{
                modalShow("不合格", "不正");
            }
        });

        $(".selectpicker").on('changed.bs.select', function(e){
            let text = $("div", $(e.target).next());
            text.css("font-size", fontsize); 
            let googleIcon = $("span", $(e.target).next());
            googleIcon.css("font-size", fontsize);
            
        });
        $(".selectpicker").on('shown.bs.select', function(e){
            // 選択肢のフォントサイズ調整(resizedメンバを新設し、最初の一回のみ調整)
            if(e.target.resized === undefined){
                e.target.resized = true;

                const $dropdown = $(e.target).parent();
                
                $(".google-icon-font", $dropdown).css("font-size", fontsize);
                $(".dropdown-menu", $dropdown).css("font-size", fontsize);
            }
        });
        let count = 0;
        $(".selectpicker").on('loaded.bs.select', function(e){
            //console.log("select loaded!");
            count++;
            if(count == $("select").length){
                // 初期表示でセンタリング
                $(".filter-option").css("text-align", "center");
                // 選択肢のセンタリング
                $(".dropdown-menu").css("text-align", "center");
                
                // 初期選択表示のフォントサイズ
                $(".google-icon-font").css("font-size", fontsize);

                
            }
        });

    
        // モーダルを非表示にした際に、ajax処理により、次の問題を描画する
        $("#trainingModal").on('hidden.bs.modal', function(){
            //if( $("#trainingModal .modal-title").html() == gon.modal_titles["合格"]) {
            if( $("#modal-title-key").val() == "合格") {
                $.get("/create_history", function(){
                    location.reload();
                });
            }
        });
    } 

});