import { imageSizing } from "../training_common";
import choiceFitting from "./image_common";
export default function no1Fitting()
{
    // 画像の拡大
    $(".image-situation-table img").each(function(){
        imageSizing($(this), $(".situation-border").width(), $(".situation-area").height() * 0.6);
    });

    let fontsize = 40;
    while(1){
        $(".situation-border").css("font-size", fontsize);
        let allOK = true;
        $(".situation-border").each(function(){
            if($(this).height() > $(".situation-area").height()){
                allOK = false;
                return false;
            }
        });

        if(allOK){
            //console.log("fontsize5: " + fontsize);
            break;
        }
        fontsize -= 1;
    }

    // 選択肢のフォントサイズを調整
    choiceFitting(fontsize);
    

    // ２択のうち大きい方の表示サイズに高さを調整する
    let borderAreaObject = -1;
    $(".situation-border").each(function(){
        if(borderAreaObject == -1){
            borderAreaObject = $(this);
        }
        else{
            if($(this).height() > borderAreaObject.height()){
                borderAreaObject.height($(this).height());
            }
            else{
                $(this).height(borderAreaObject.height());
            }
        }
    });
}