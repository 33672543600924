import {modalShow} from '../training_common'
import calculator from '../calculator'

export default function no11Siki(){
    calculator();
    $("#btn-attention-finish").on('click', function(){
        
        $("select.attention-answers").each(function(){
            let $dropdwon = $(this).parent(); 
            $dropdwon.removeClass('miss');
            
            //console.log($(this).data('answer'));

            if( $(this).data('answer') != $(this).val() ){
                $dropdwon.addClass('miss');
            }
        });

        if( $(".dropdown").hasClass("miss") ){
            modalShow("不合格", "不正");
        }
        else{
            gon.count++;
            modalShow("合格", "正解");
        }
    });
    
}