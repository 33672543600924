import {modalShow} from '../training_common'
import calculator from '../calculator'

export default function no4Calc(level){
    calculator();

    const targets = JSON.parse($("#targets").val());
    $("#memory-finish").on('click', function(){
        let allok = true;
        $(".selectpicker").each(function(){
            const $dropdown = $(this).parent();
            $dropdown.removeClass("miss");
            //console.log("selected value: " + $(this).val() + ", correct value: " + $(this).data("answer"));
            let dataId = "#" + $(this).attr('id').replace("answer", "data");
            //console.log($(dataId).text());
            //console.log(gon.targets[0] == 0);
            //console.log(gon.level == 'high');
            
            // 計算前の数字に対するターゲット
            let beforeCalc = targets[0];
            // 計算後の数字に対するターゲット(やさしいの場合は、計算前後で同じターゲット）
            let afterCalc = level == "low" ? targets[0] : targets[1];

            //console.log("検証：　" + $(this).data("answer") + 'x' + afterCalc);
            // 計算前の数字とターゲットが同じ場合
            if( $(dataId).text() == beforeCalc){
                if( $(this).val() != '○' ){
                    $dropdown.addClass("miss");
                    allok = false;
                }
            }
            //計算後の数字にターゲットが含まれる場合
            else if( $(this).data("answer").toString().indexOf(afterCalc) >= 0 ){
            //else if( $(this).data("answer") == afterCalc ){
                if( $(this).val() != '○' ){
                    $dropdown.addClass("miss");
                    allok = false;
                }
            }
            // 計算結果が違う場合
            else if( $(this).val() != $(this).data("answer") ){
                $dropdown.addClass("miss");
                allok = false;
            }
        });

        if(allok == false){
            modalShow("不合格", "不正");
        }
        else{
            modalShow("合格", "正解");
        }

    });
}