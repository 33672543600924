import {modalShow} from './../training_common'
import anime from 'animejs'
import html2canvas from 'html2canvas';
import { userAgent } from './../training_common';

export default function no4Func(){
    /*
    $(".to_image").offset(
        {
            top: $(".eye-main-border").offset().top + $(".eye-main-border").height()/2,
            left: $(".eye-main-border").width()/2
        });
      */  
    /*
    html2canvas(document.querySelector(".for-capture")).then(function(canvas){
            console.log(canvas);
            const link = document.createElement('a')
            link.href = canvas.toDataURL()
            link.download = `export_image.png`
            link.click()
    });
    */
    
    const starCount = $(".eye-no4-star").length;
    const no4Params = JSON.parse($("#no4-params").val());
    const direction = no4Params.direction; 
    const includedCount = no4Params.includedCount;
    let speedParam = no4Params.speed; 

    let colum; 
    let row;
    let controlRangeLeverage;  // 同じ行、または列の各星の遅延時間を、星の移動時間の何倍のレンジを対象として設定するか。
    let length = 0; //星の移動距離
    const indexArray = []

    if(direction == 0 || direction == 2){ // 横方向
        controlRangeLeverage = 3;
        colum = 5;
        row = starCount / colum;
        for(let i = 0; i < colum ; i++ ){
            indexArray.push([]);
            for(let j = 0; j < row ; j++ ){
                indexArray[i].push( i * row + j);
            }
        }
        //alert( $(".eye-main-border").height() + "x" + $(".fb-main-contents-height").height());
        $(".eye-no4-star").height($(".eye-main-border").height()/5.2); //上下の枠線からはみ出るため、星を少し小さめにする(5 -> 5.2)
        $("#star-table-h tr").height($(".eye-no4-star").height());
        length = $(".eye-main-border").width() - $(".eye-no4-star").width();
    }
    else{   // 縦方向
        speedParam *= 0.7; // 縦方向は移動距離が短いため、速度を70％に押させる。
        controlRangeLeverage = 5;
        colum = 10;
        row = starCount / colum;
        for(let i = 0; i < colum ; i++ ){
            indexArray.push([])
            for(let j = 0; j < row ; j++ ){
                indexArray[i].push(j * colum + i);
            }
        }
        
        $(".eye-no4-star").width($(".eye-main-border").width()/10);
        $("#star-table-v td").width($(".eye-no4-star").width());
        // chromeの上下で必要。safariでは不要
        if(userAgent == "chrome")
            $("#star-table-v").offset({top: $("#star-table-v").offset().top + $(".eye-no4-star").height()/2});  
        
        
        length = $(".eye-main-border").height() - $(".eye-no4-star").height();//parseInt($(".eye-main-border").height()/$(".eye-no4-star").height()) * $(".eye-no4-star").height() * 0.9 ; //4.5
    }
    //console.log(indexArray);

    $("#btn-eye-start").on('click', function(){
        $.each(indexArray, function(loop, group){
            shootingStarGroup(group, length, direction, speedParam, controlRangeLeverage);
        });
        $(this).toggle();
    });

    let clickedCount = 0;
    $("[id^=star-anime]").on("click",function(){
        if( $('a', $(this)).data("target") === true ){
            // クリック済の星をクリックした際は何もしない。
            if($("img", this).attr("src") == '/assets/trainings/eye/no4/star-non.png'){
                return false;
            }
            // 未クリックであれば星の画像を数字なしに置き換える。
            $("img", this).attr("src", '/assets/trainings/eye/no4/star-non.png');
            //$(this).addClass('clicked-image');
            clickedCount++ ;
            //console.log(includedCount, clickedCount);
            if(includedCount == clickedCount) modalShow("合格", "正解");
        }
        else{
            modalShow("不合格", "不正");
        }

        //
        //console.log($(this).attr("id"));
        //$("span:first-child", $(this)).css('color', 'red');
        //console.log(parseInt($(this).text()));
        //console.log($('a', $(this)).data("target"));
    });

}

function shootingStarGroup(group, animeLength, direction, speedParam, controlRangeLeverage){
    const targets = [];
    $.each(group, function(loop, element){
        targets.push("#star-anime" + element);
    });

    // スピードとランダムパラメータ
    // level: high/middle/low = 10000/15000/20000 
    const animationTime = speedParam // ミリ秒で指定する。指定した秒数でアニメーション距離を完走する。
    // const distanceParam = animationTime/15; // 星と星の最低間隔をミリ秒で指定する。
    const distanceParam = animationTime * controlRangeLeverage/20; // 星と星の最低間隔をミリ秒で指定する。
    let reveseStartParam = animationTime/40; // グループの全員が完走した後に、再スタートさせる最大遅延をミリ秒で指定する。


    // グループ内の星のスタート遅延管理
    let i = 0; //　グループのループ管理
    let startPoint = 0; // ランダム生成の開始数値
    const delayControl = function(){
        i++;
        // 画面になるべく隙間を作らないうにseedParamを２倍にしてから、区間を分割。
        const section = parseInt(animationTime * controlRangeLeverage/targets.length); 
        const delay = anime.random(startPoint, section * i);
        startPoint = ( delay + distanceParam >= section ) ? delay + distanceParam : section ;
        return delay ;
    }
    

    const params = {
        targets: targets,
        loop: true,
        easing: 'linear',
        direction: (direction == 0 || direction == 1) ? "normal" : "reverse", 
        delay: function(){ return delayControl() },
        endDelay: function(){
            //　下から右から開始時は、endDelayの遅延からスタートする。
            if(direction == 2 || direction == 3){
                reveseStartParam = parseInt(animationTime * 2/targets.length); 
            }
            return anime.random(0, reveseStartParam);
        },
        update: function(a){
            const startPoint = (direction == 0 || direction == 1) ? a.animations[0].tweens[0].from.numbers[0] : a.animations[0].tweens[0].to.numbers[0];
            const endPoint = (direction == 0 || direction == 1) ? a.animations[0].tweens[0].to.numbers[0] : a.animations[0].tweens[0].from.numbers[0];
            const valueFloat = function(str){ return parseFloat(str.replace('px', '')) }
            $.each(a.animatables, function(loop, e){
                if( endPoint == valueFloat(a.animations[loop].currentValue) ){
                    $("#" + e.target.id).css('visibility', "hidden");
                }
                else{
                    if(direction == 0 || direction == 1){
                        if(valueFloat(a.animations[loop].currentValue) > startPoint){
                            $("#" + e.target.id).css('visibility', "visible");
                        }        
                    }
                    else{
                        if(valueFloat(a.animations[loop].currentValue) < startPoint){
                            $("#" + e.target.id).css('visibility', "visible");
                        }
                    }
                }
            });
        },
        duration: animationTime
    };

    if(direction == 0 || direction == 2) params.translateX = animeLength;
    else params.translateY = animeLength;
    
    anime(params);
}
