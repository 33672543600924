import html2canvas from 'html2canvas'
import {modalShow} from '../training_common'

let fbCanvas;
let canvasId = "no9-canvas"
var startX;
var startY;
var endX;
var endY;
var startFlag = false;
var line;
var lineCount = 0;
var fixed_point = [];
var history = [];
const plotPoints = [];
const scaled_collect_lines = [];

export default function no9Drawing(){

    let tableWidth;
    let tableHeight;

    //$('.attention-table').width($('.attention-table').width() * 0.8);
    tableWidth = $('.attention-table').outerWidth();
    tableHeight = $('.attention-table').outerHeight();
    // console.log(tableWidth, tableHeight);
    fbCanvas = new fabric.Canvas(canvasId);
    fbCanvas.setHeight(tableHeight);
    fbCanvas.setWidth(tableWidth);

    // html2canvasすると、なぜか枠線が膨張するため、枠線を捕捉してから実行
    $(".attention-table").css("border", "0.1rem solid black");
    $(".attention-table td").css("border", "0.1rem solid black");
    //$(".attention-table").css("padding", "0 0 0 0");

    // console.log(tableWidth, tableHeight);
    // console.log($('.attention-table').outerWidth(), $('.attention-table').outerHeight());

    // html2canvasはwindow.on.load後に実行しないとキャプチャがずれるので注意
    html2canvas(document.querySelector(".attention-table"),{width: tableWidth + 2, height: tableHeight + 2 }).then(function(canvas){
        //  console.log(canvas);
        // const link = document.createElement('a')
        // link.href = canvas.toDataURL("image/png")
        // link.download = `export_image.png`
        // link.click()
        
        //$('.attention-table').parent().hide();  
        //$(".c-modal").addClass("c-hidden"); 
        
        fabric.Image.fromURL(canvas.toDataURL(), function(oImg){
            oImg.scaleToWidth(tableWidth);
            oImg.scaleToHeight(tableHeight);
            fbCanvas.setBackgroundImage(oImg, fbCanvas.renderAll.bind(fbCanvas)); 
        });                
    });

    // 各セルの中央座標を求める
    const basis = [0.1, 0.3, 0.5, 0.7, 0.9];
    
    $.each(basis, function(idx1, x){
        $.each(basis, function(idx2, y){
            plotPoints.push([x * (tableWidth), y * (tableHeight)]);
        });
    });
    //console.log(plotPoints);
        
    const collectLines = JSON.parse($("#collect-lines").val());
    // 正解の線を座標で求める    
    //$.each(gon.collect_lines, function(idx1, line){
    $.each(collectLines, function(idx1, line){
        const temp = [];
        $.each(line, function(idx2, points){
            temp.push([points[0] * fbCanvas.width, points[1] * fbCanvas.height]);
        });
        scaled_collect_lines.push(temp);
    });
    //console.log(scaled_collect_lines);
    //console.log(gon.scaled_collect_lines);

    //モーダルの非表示タイミングを制御
    let modalControl = 0;
    fbCanvas.on("after:render", function(e){
        modalControl++;
        if(modalControl == 2){
            // 
            // 本番でキャンバスがセンタリングできないため、強制的に移動
            if($("#no9-canvas-wrapper").length){
                // console.log($("#no9-canvas-wrapper").offset().left);
                // console.log($(".canvas-area").width(), fbCanvas.width);
                $("#no9-canvas-wrapper").offset({left: $("#no9-canvas-wrapper").offset().left + ($(".canvas-area").width() - fbCanvas.width)/2});
            }
            
            $(".c-modal").addClass("c-hidden");
        }
    });

    // キャンバス設定
    fbCanvas.isDrawingMode = false;

    fbCanvas.on('mouse:down', function(options){
        mouseDownListner(options.pointer);
    });
    
    fbCanvas.on('mouse:up', function(){
        mouseUpListner();
    });

    fbCanvas.on('mouse:move', function(options){
        mouseMoveListner(options.pointer);
    });

    $("#btn-attention-undo").on('click', function(){
        undo();
    });


    $("#btn-attention-finish").on('click', function(){
        // console.log(fbCanvas._objects.length);
        // console.log(scaled_collect_lines.length);
        // console.log("正解ライン" + gon.collect_lines);

        if(scaled_collect_lines.length != fbCanvas._objects.length){
            modalShow("不合格", "不正");
            return;
        }
        
        let collect_count = 0;
        $.each(fbCanvas._objects, function(_n, line){
            $.each(scaled_collect_lines, function(_i, collect_line){
                if( JSON.stringify( [[line.x1, line.y1], [line.x2, line.y2]].sort() ) == JSON.stringify( [[collect_line[0][0], collect_line[0][1]], [collect_line[1][0], collect_line[1][1]]].sort() ) ){
                    collect_count++;
                    return ;
                }
            });
        });

        //console.log("正解数：　" + collect_count);
        
        if(collect_count == scaled_collect_lines.length ) {
            gon.count++;
            //console.log("合格")
            modalShow("合格", "正解");
        }
        else{
            //console.log("不合格")
            modalShow("不合格", "不正");
        }
        
    });
    

    // 間違えた場合のみ全部で何個あるかを表示する(ただし、初級は最初から表示)
    $("#trainingModal").on('hidden.bs.modal', function(){
        // ヒント表示時は無効(20240404)
        if( $("#modal-title-key").val() != "ヒント表示") 
            $(".no9-visible")[0].style.visibility = "visible";
    });
    
}


// 戻る
function undo(){
    if(fbCanvas._objects.length > 0){
        history.push(fbCanvas._objects.pop());
        fbCanvas.renderAll();
    }
}

// canvasのonload時にstartFlag=trueを設定しておきます。
function mouseDownListner(pointer){
    startFlag = true;
    //既存の直線の数を格納しておく
    lineCount = fbCanvas._objects.length ;
    //始点決定（始点、終点座標はテキストボックスに表示されているとします）
    //console.log('(' + pointer.x + ',' + pointer.y + ')');
    fixed_point = near_point(pointer.x, pointer.y);
    
    startX = fixed_point[0]; //document.getElementById('startXTextId').value;
    startY = fixed_point[1]; //document.getElementById('startYTextId').value;
    
    //DrawingModeをtrueにします
    fbCanvas.isDrawingMode = true;
}

function mouseUpListner(e){
    // ３個分しか直線を描画できないように制御
    const target = fbCanvas._objects[fbCanvas._objects.length - 1];
    const ruleResult = lineLengthRule(target);
    //console.log(ruleResult);
    if(ruleResult == false){
        fbCanvas.remove(fbCanvas._objects.pop());
        fbCanvas.renderAll();
    }
    /*
    const width = Math.abs(target.x1 - target.x2);
    const height = Math.abs(target.y1 - target.y2);
    if( width == 0) console.log("縦線で 長さは" + height/fbCanvas.height);
    else if( height == 0) console.log("横線で 長さは" + width/fbCanvas.width);
    else console.log("斜め線で 長さは" + width * Math.sqrt(2) / (fbCanvas.width * Math.sqrt(2)));
    */
    startFlag = false;
    fbCanvas.isDrawingMode = false;
    //zahyo();
}

function lineLengthRule(target){
    const width = Math.abs(target.x1 - target.x2);
    const height = Math.abs(target.y1 - target.y2);
    let length ;
    if( width == 0) {
        length = height/fbCanvas.height ;
        //console.log("縦線で 長さは" + length );
    }
    else if( height == 0) {
        length = width/fbCanvas.width;
        //console.log("横線で 長さは" + length);
    }
    else {
        length = Math.sqrt( Math.pow(width, 2) + Math.pow(height, 2) ) / Math.sqrt( Math.pow(fbCanvas.width, 2) + Math.pow(fbCanvas.height, 2) );
        //length = Math.round( width * Math.sqrt(2) / (fbCanvas.width * Math.sqrt(2)) * 10 );
        //console.log("斜め線で 長さは" + length );
    }
    // 長さを１０倍して整数部を増やして、それを二乗して、その結果を四捨五入
    let ampLength = Math.round( Math.pow(length * 10, 2) );
    //console.log(width * Math.sqrt(2) / (fbCanvas.width * Math.sqrt(2)));
    //console.log(Math.sqrt( Math.pow(width, 2) + Math.pow(height, 2) ) / Math.sqrt( Math.pow(fbCanvas.width, 2) + Math.pow(fbCanvas.height, 2) ) * 10);
    // 16が３マス分の長さ
    if(ampLength == 16) return true;
    else return false;
}

// 軌跡を書く
function mouseMoveListner(pointer){

    if(startFlag == true){
        //前の線(軌跡)を消す
        if(fbCanvas._objects.length == lineCount + 1)
            fbCanvas.remove(fbCanvas._objects.pop());
        //終点座標を求める
        fixed_point = near_point(pointer.x, pointer.y);
        endX = fixed_point[0];
        endY = fixed_point[1];
        //endX = pointer.x;
        //endY = pointer.y;
        //軌跡を描く
        drawLine();
    }
}

function drawLine(){
    line = new fabric.Line([ Number(startX), Number(startY), Number(endX), Number(endY) ],
        {
            stroke: 'blue',
            strokeWidth: 4,
            selectable: false
        }
    );
    
    fbCanvas.add(line);
}

function near_point(x, y){
    var temp = [];
    $.each(plotPoints, function(index, val){
        temp.push(Math.pow(Math.abs(val[0] - x), 2) + Math.pow(Math.abs(val[1] - y), 2));
    });
    const aryMin = function (a, b) {return Math.min(a, b);}
    return plotPoints[ temp.indexOf(temp.reduce(aryMin)) ];
}