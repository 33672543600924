import { imageSizing, fontSizeFittingByHeight } from "../training_common";
import choiceFitting from "./image_common";
export default function no3Fitting(seen)
{
    if(seen == 0){
        // 画像の拡大
        imageSizing($(".image-situation-table img"), $(".situation-area").width(), $(".situation-area").height() * 0.9);
    }
    else if(seen == 1){
        // 画像の拡大
        let $img;
        $img = $(".image-situation-table .first-image img");
        imageSizing($img, $(".situation-area").width(), $(".situation-area").height() * 0.9);
        $img = $(".image-situation-table .second-image img")
        imageSizing($img, $(".situation-area").width(), $(".situation-area").height() * 0.9);

        // 最初の画像のコメントのフォント調整
        let fontsize = 40;
        $img = $(".image-situation-table .first-image img");

        fontsize = fontSizeFittingByHeight($(".situation-wrapper .situation-border"), $img.height() * 1.1, 1, fontsize, $(".situation-wrapper .image-situation-table"))
        

        // 矢印フォント調整
        $(".google-icon-font").css("font-size", fontsize * 2);
        
        // 何かがあったみたい・・・のサイズ調整
        fontSizeFittingByHeight($(".hint-comment"), $(".fb-main-contents-height .height-10").height(), 1, fontsize)
        

        $(".situation-border").height($(".situation-area").height());
    }
    else if(seen == 2){
        // 画像の拡大
        let $img = $(".image-situation-table .first-image img")
        imageSizing($img, $(".situation-wrapper").width() * 0.7, $(".situation-area").height() * 0.9);
        $img = $(".image-situation-table .second-image img")
        imageSizing($img, $(".situation-wrapper").width() * 0.7, $(".situation-area").height() * 0.9);
        $img = $(".image-situation-table .third-image img")
        imageSizing($img, $(".situation-wrapper").width(), $(".answer-area").height() * 0.9);
        
        // 2つの画像のコメントのフォント調整
        // 文書が長い方に.font-adjustが付与されている。フォントサイズ決定後に、もう一方の画像のコメントにも同じフォントサイズを適用する。
        let fontsize = 40;
        fontsize = fontSizeFittingByHeight($(".situation-border.font-adjust"), $(".situation-area").height(), 1, fontsize);
        // もう一方の画像のコメントにも適用
        $(".situation-border").css("font-size", fontsize);

        // 矢印フォント調整
        $(".google-icon-font").css("font-size", fontsize * 2);
        
        // 何かがあったみたい・・・のサイズ調整
        fontsize = 40;

        fontSizeFittingByHeight($(".hint-comment"), $(".comment-area").height(), 1, fontsize);
        $(".situation-area .situation-border").height($(".situation-area").height());
    }
    
    
            
    let fontsize = 35;
    // 選択肢枠の調整
    choiceFitting(fontsize, seen);
}