import {redirectNextTraining, trainingInitialWindowSetting, imageSizing, fontSizeFittingByHeight, fontSizeFittingByWidth} from './training_common'
import {no1Func, setCanvas} from './read_writes/no1_same_draw'
import no2Func from './read_writes/no2_block'
import no4Func from './read_writes/no4_sentense'
import no5Func from './read_writes/no5_hantai'



let isPerfect = 1 ; // 0/1: 不正解あり/全正解 

$(document).on('turbolinks:load', function(){
    if( $('#exercise-type').val() == "ReadWrite"){

        const patternNo = $('#exercise-pattern_no').val();
        const previewMode = $('#preview-mode').val();
        const redirectUrl = $("#redirect-url").val();
        const workId = $("#work-id").val();
        const workLevel = $("#work-level").val();
        let loopInfo = JSON.parse($("#loop-info").val());
        const loopTotalCount = loopInfo.total ;
        let loopCounter = loopInfo.count;

        trainingInitialWindowSetting();
        if (patternNo == "1" ){
            no1Func();
        }
        if (patternNo == "2" ){
            no2Func();
        }
        if (patternNo == "4" ){
            $(window).on("load", function(){
                // 画像の調整
                imageSizing($(".no4-img-area img"), $(".no4-img-area").width(), $(".no4-img-area").height() * 0.9);
                
                // 選択肢のフォントサイズ調整
                let fontsize = 25;
                fontSizeFittingByHeight($(".read_write-no4-table td label"), $(".no4-choice-area").height() * 0.9, 1, fontsize, $(".no4-choice-area-main"));
                
                // 設問枠の高さを統一
                let tdMaxHeight = 0;
                $(".read_write-no4-table td").each(function(){
                    if($(this).height() > tdMaxHeight){
                        tdMaxHeight = $(this).height();
                    }
                });
                $(".read_write-no4-table td").height(tdMaxHeight);

                // ルビ付きのラベルか否かでクラスを振る。チェックボックスの大きさ調整(jQueryで動的対応できないためcssで対応)
                $(".read_write-no4-table td label").each(function(){
                    if( $("ruby", $(this)).length > 0){
                        $(this).prev().addClass("ruby");
                    }
                    else{
                        $(this).prev().addClass("no-ruby");
                    }
                });
            });
            

            no4Func();
        }
        
        if (patternNo == "5" ){
            
            // フォント調整の対象とするため最大長の文を求める。
            let maxLengthObject;
            let maxLength = 0;
            $(".reverse-sentense").each(function(){
                if($(this).width() > maxLength){
                    maxLength = $(this).width();
                    maxLengthObject = $(this);
                }
            })

            // 選択肢のフォントサイズ調整
            let fontsize = 50;
            fontsize = fontSizeFittingByWidth($(".read_write-no5-border"), $(".no5-sentense-area").width() * 0.9, 1, fontsize, maxLengthObject);
            fontSizeFittingByHeight($(".read_write-no5-border"), $(".height-50").height() * 0.9, 1, fontsize);
            
            
            no5Func();
        }
        
        // モーダルを非表示にした際に、ajax処理により、次の問題を描画する
        $("#trainingModal").on('hidden.bs.modal', function(){
            if( $("#modal-title-key").val() == "合格") {
            //if( $("#trainingModal .modal-title").html() == gon.modal_titles["合格"]) {
                //alert(loopTotalCount);
                if(loopCounter + 1 == loopTotalCount){
                    $.get("/save_training_history", {exercise_id: $('#exercise-id').val(), score: isPerfect}, redirectNextTraining);
                }
                else {
                    let workHistory = [];
                    if($("#current-work-id").length){
                        const currentWorkId = parseInt($("#current-work-id").val());
                        workHistory = JSON.parse($("#work-history").val());
                        workHistory.push(currentWorkId);
                        workHistory = $.uniqueSort(workHistory);
                        $("#work-history").val(JSON.stringify(workHistory));
                    }
                    $.get("/ajax_read_write_exercise", {work_id: workId, preview_mode: previewMode, pattern_no: patternNo, count: loopCounter + 1, work_level: workLevel, work_history: workHistory}, function(){

                        loopInfo = JSON.parse($("#loop-info").val());
                        loopCounter = loopInfo.count;

                        if (patternNo == "1" ){
                            setCanvas("student_canvas", "teacher_canvas", "teacher_canvas");
                        }
                    });
                }
            }
            else if( $("#modal-title-key").val() == "レベル変更") {
            // else if( $("#trainingModal .modal-title").html().indexOf("レベル") >= 0) {
                location.href = redirectUrl;
            }
            else if( $("#modal-title-key").val() != "ヒント表示"){
                isPerfect = 0;
            }
        });
    }
});

