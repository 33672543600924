import {modalShow} from '../training_common'

export default function no8_no9Matrix(){
    
    // $(".memory-no3-table td").each(function(){
    //     $(this).on("click", function(){
    //         $(this).toggleClass("memory-matrix-clicked");
    //     });  
    // })

    $(".memory-no3-table a").on("click", function(){
        $(this).parent().toggleClass("memory-matrix-clicked");
    });
    
    

    $("#memory-finish").on("click", function(){
        //console.log("クリック")
        let count = 0;
        let missTake = false;
        $(".memory-matrix-clicked").each(function(){
            //console.log( $("a", this).text() );
            if( !$("a", this).hasClass("collect") ) {
                missTake = true;
            }
            count++
        })

        //console.log("count: " + count + ", include-count: " + $(this).data("target-include-count"));

        if(count == $(this).data("target-include-count") && !missTake ){
            modalShow("合格", "正解");
        }
        else
            modalShow("不合格", "不正");
    });
}