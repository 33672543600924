import {modalShow, trainingInitialWindowSetting} from './training_common'

$(document).on('turbolinks:load', function(){
    if($(".finish-training-area").length > 0){
        
        // パスボタンを非表示
        //$("#btn-skip").hide();

        trainingInitialWindowSetting();

        if(gon.showed_finish == false){
            modalShow("トレーニング終了", "");
        }

        $(".finish-levels-table th").css("white-space", "nowrap");

        let fontsize = 50;
        $(".finish-levels-table").css("font-size", fontsize);
        if($(".finish-levels-table").height() > $(".finish-training-area .height-100").height() * 0.9){
            fontsize -= 1;
            while(1){
                $(".finish-levels-table").css("font-size", fontsize);
                if($(".finish-levels-table").height() < $(".finish-training-area .height-100").height() * 0.9){
                    break;
                }
                fontsize -= 1;
            }
        }

    } 

});