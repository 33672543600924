export default function choiceFitting(fontsize, seen = 0)
{
    if(seen != 2){
        // const maxFontSize = 30;
        // if(fontsize > maxFontSize) fontsize = maxFontSize;
        // 選択肢の文が長くて、折り返しが発生する場合にフォントを下げる。
        $(".image-choice").css("font-size", fontsize);
        if($(".answer-area").width() * 0.8 < $(".answer-table").width()){
            fontsize -= 1;
            while(1){
                $(".image-choice").css("font-size", fontsize);
                if($(".answer-area").width() * 0.8 > $(".answer-table").width()){
                    //console.log("fontsize5-2: " + fontsize);
                    break;
                }
                fontsize -= 1;
            }
        }

        // フォントが大きくて選択肢の高さが、枠をはみ出る場合はフォントを下げる。
        if($(".answer-area").height() * 0.8 < $(".answer-table").height()){
            fontsize -= 1;
            while(1){
                $(".image-choice").css("font-size", fontsize);
                if($(".answer-area").height() * 0.8 > $(".answer-table").height()){
                    //console.log("fontsize5-3: " + fontsize);
                    break;
                }
                fontsize -= 1;
            }
        }
    }
    else{
        // スペースの問題でおりか返を許可
        $(".image-choice label").css("white-space", "normal");
        $(".image-choice").css("font-size", fontsize);
        // フォントが大きくて選択肢の高さが、枠をはみ出る場合はフォントを下げる。
        if($(".answer-area").height() * 0.8 < $(".answer-table").height()){
            fontsize -= 1;
            while(1){
                $(".image-choice").css("font-size", fontsize);
                if($(".answer-area").height() * 0.8 > $(".answer-table").height()){
                    //console.log("fontsize5-3: " + fontsize);
                    break;
                }
                fontsize -= 1;
            }
        }
    }
    
}