import { trainingInitialWindowSetting } from './training_common';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
//import interactionPlugin from '@fullcalendar/interaction';

$(document).on('turbolinks:load', function(){
    if($("#calendar").length > 0){
      trainingInitialWindowSetting();

      // 今月＋１ヶ月までしか表示させない
      const today = new Date()
      const nextMonth_lastDate = new Date(today.getFullYear(), (today.getMonth()+3), 0 )
      var endDate = `${nextMonth_lastDate.getFullYear()}-${zeroPadding(nextMonth_lastDate.getMonth() + 1, 2)}-01`
      
      //var calendarEl = document.getElementById('calendar');
      var calendarEl = $('#calendar').get(0);
      //console.log(gon.histories);
      var calendar = new Calendar(calendarEl, {
          height: $(".fb-main-contents-height").height() * 0.95,
          locale: 'ja',
          buttonText: {
          
            today:    '今日',
          
          },
          // 当月以外を表示させるか否か
          // showNonCurrentDates: false, 
          validRange: function(){
              return {
                  start: "2020-08-01",
                  end: endDate
              };
          },
          // events: [
          //     {
          //         title: "記録",
          //         url: "www.google.com",
          //         start: '2020-11-16',
          //         end: '2020-11-16',
          //         display: 'block'
          //     },
          //     {
          //         title: "記録",
          //         url: "www.google.com",
          //         start: '2020-09-30',
          //         end: '2020-09-30',
          //         display: 'block'
          //     },
          // ],
          events: gon.histories,
          //dateClick: function(info) {
            //  console.log(info);
              // alert('Clicked on: ' + info.dateStr);
              // alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
              // alert('Current view: ' + info.view.type);
              // change the day's background color just for fun
              // info.dayEl.style.backgroundColor = 'red';
            //},
          plugins: [ dayGridPlugin ],
          viewDidMount: function(e){
              //$(".fc-today-button").text("本日");
              //console.log(e.view.title);
          },
      });
  
      calendar.render();
  
      $(document).on("click", ".fc-prev-button", function(e){
          //$(".fc-today-button").text("本日");
          // console.log(calendar.view.title);
          // console.log(calendar.view.activeStart);
          // console.log(calendar.view.activeEnd);
          // console.log(calendar.view.currentStart);
          // console.log(calendar.view.currentEnd);
      });
    }
  });
  
  // NUM=値 LEN=桁数
  function zeroPadding(NUM, LEN){
      return ( Array(LEN).join('0') + NUM ).slice( -LEN );
  }
  
  