import {modalShow} from './../training_common'
import {pastSec, startTimer, stopTimer, displayTimeOnModal } from './eye_common';

export default function no1Func(targetNumbers){

    // ターゲットナンバー格納配列のインデックスを初期化
    let index = 0; 

    // 数字をクリックした際の動作
    $(".eye-table td a").on('click', function(){
        // タイマーが未スタートであれば、スタートさせる。
        if(pastSec == 0) startTimer(0);
        // ターゲットナンバーを正しくクリックした場合
        if(targetNumbers[index] == $(this).text()){
            // クリアした数字は非クリック化(aタグ除去)
            var num = $(this).contents().unwrap();
            // 数字の灰色化
            num.wrap("<span class='eye_no1-clicked'>");
            index++;

            // ターゲットナンバーの表示を更新
            $("#eye-table-target").text(targetNumbers[index]);
            
            // 全部クリックしたら修了
            if(targetNumbers.length == index){
                stopTimer();
                modalShow("合格", "正解");
                displayTimeOnModal();
            }
        }
        else{
            stopTimer();
            modalShow("不合格", "不正");
        }
    });
}