// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

window.jQuery = window.$ = require("jquery");
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap-select';
import '../stylesheets/application';

import '@fortawesome/fontawesome-free/js/all' ;

import 'fabric/dist/fabric.min';
window.resemble = require('resemblejs');

import './utils_login';
import './training_common';
import './training_confirm_date';
import './training_eye';
import './training_attention';
import './training_memory';
import './training_read_write';
import './training_image';
import './training_finish';
import './training_history';
import './traininig_free';
import './training_user_profile';

import './training_calendar';

import 'fitty';
import 'animejs';
import 'html2canvas';



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
