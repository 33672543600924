import {modalShow} from '../training_common'

export default function no6Sentense(){
    
    $(".memory-no6-contents a").on('click', function(){
        $(this).toggleClass("clicked");
    });

    $("#memory-finish").on('click', function(){
        let allok = true;
        let missKind = 0 ; // 1/2/3:文章/過剰/不足
        $(".memory-no6-contents input").each(function(index){
            //console.log( "user: " +  $(this).prop("checked") );
            //console.log( "system: " + $(this).data("correct") );
            if( $(this).prop('checked') != $(this).data("correct")){
                allok = false
                //console.log("文章選択ミス: " + (index + 1) );
                missKind = 1;
            }
        });

        let wordLength = 0;
        $(".memory-no6-contents a").each(function(){
            // console.log($("rb", this).text());
            // console.log($(this).data("kind"));
            //console.log( $("rb", this).text().length );
            //console.log( "include :" + includeKeyWord($("rb", this).text()) );
            //console.log($("rt", this).text().length);
            
            // ひらがな読みで３文字以上か否かを判定するため、次の変数を使う
            // まず、よみがな(rt)があれば、そちらの長さを採用、次によみがなが無い(カタカナ)であればその文字そのもの(rb)を採用
            wordLength = $("rt", this).text().length ;
            if(wordLength == 0) wordLength = $("rb", this).text().length ;
            
            //console.log(wordLength);
            //console.log($(this).hasClass("redcolor"));
            
            // ３文字以上でない,または、キーワードを含まないのにクリックされている（過剰選択）
            if( (!(wordLength >= 3) || !includeKeyWord($(this).data("kind"))) && $(this).hasClass("clicked") ){
                allok = false;
                //console.log("過剰選択: " + $("rb", this).text());
                if(missKind == 0) missKind = 2;
            }
            // ３文字以上でキーワードを含むのにクリックされていない（選択不足）
            if( (wordLength >= 3 && includeKeyWord($(this).data("kind"))) && !$(this).hasClass("clicked") ){
                allok = false;
                //console.log("選択不足: " + $("rb", this).text());
                if(missKind == 0) missKind = 3;
            }

        });
        // $(".redcolor").each(function(){
        //     console.log($("rb", this).text() );
        // });

        if(allok == false){
            if(missKind == 1)
                modalShow("不合格", "不正");
            else if(missKind == 2)
                modalShow("不合格", "過剰");
            else
                modalShow("不合格", "不足");
        }
        else{
            modalShow("合格", "正解");
        }
        
    });
}

// キーワードが含まれるか判定
function includeKeyWord(str){
    const kewordList = JSON.parse($("#keyword-list").val());
    let flag = 0;
    $.each(kewordList, function(loop, element){
        //console.log(element);
        if ( str.indexOf(element) != -1) {
            //strにhogeを含む場合の処理
            //console.log("一致")    
            flag = 1;
            return;
        }
    });
    return flag;
}