import {modalShow, redirectNextTraining, trainingInitialWindowSetting, waitImageLoadedForAfterAjax, imageSizing} from './training_common'
import choiceFitting from './images/image_common';
import no1Fitting from './images/no1_situation'
import no2Fitting from './images/no2_situation'
import no3Fitting from './images/no3_situation'

let isPerfect = 1 ; // 0/1: 不正解あり/全正解 
let subLoopCounter ;
let subLoopTotalCount ;
let workId ;

$(document).on('turbolinks:load', function(){

    if( $('#exercise-type').val() == "Image"){
        const patternNo = $('#exercise-pattern_no').val();
        const previewMode = $('#preview-mode').val();
        const redirectUrl = $("#redirect-url").val();
        workId = $("#work-id").val();
        const workLevel = $("#work-level").val();
        let loopInfo = JSON.parse($("#loop-info").val());
        const loopTotalCount = loopInfo.total ;
        let loopCounter = loopInfo.count;
        subLoopTotalCount = loopInfo.sub_total ;
        subLoopCounter = loopInfo.sub_count;

        trainingInitialWindowSetting();

        if(patternNo == "1" ){
            $(window).on("load", function(){
                no1Fitting();
            });
        }
        if(patternNo == "2" ){
            $(window).on("load", function(){
                no2Fitting();
            });
        }
        if(patternNo == "3"){
            $(window).on("load", function(){
                no3Fitting(subLoopCounter);
            });
        }

        

        // finishボタンをクリック
        $("#image-finish").on('click', function(){
            // 回答の正誤判定
            judge_answer(patternNo, function(){
                var pass_flag = true;
                $(".image-choice input").each(function(){
                    var u = $(this).prop("checked").toString();
                    var v = $(this).val();
                    if(u != v) pass_flag = false;
                });
                return pass_flag;
            });
        });

        $("#trainingModal").on('hidden.bs.modal', function(){
            //console.log("modal-close!");

            if( $("#modal-title-key").val() == "合格") {
            // if( $("#trainingModal .modal-title").html() == gon.modal_titles["合格"]) {
                let count;    
                let workHistory = [];
                const currentWorkId = parseInt($("#current-work-id").val());
                if(patternNo == 3){
                    //alert(subLoopCounter);
                    if(subLoopCounter == -1){
                        count = loopCounter + 1;
                        
                        //currentWorkId = 
                        workHistory = JSON.parse($("#work-history").val());
                        workHistory.push(currentWorkId);
                        workHistory = $.uniqueSort(workHistory);
                        $("#work-history").val(JSON.stringify(workHistory));
                    
                    }
                    else{
                        count = loopCounter;
                    }
                }
                else{
                    count = loopCounter + 1;
                    
                    //currentWorkId = parseInt($("#current-work-id").val());
                    workHistory = JSON.parse($("#work-history").val());
                    workHistory.push(currentWorkId);
                    workHistory = $.uniqueSort(workHistory);
                    $("#work-history").val(JSON.stringify(workHistory));
                    
                }

                if(count == loopTotalCount){
                    $.get("/save_training_history", {exercise_id: $('#exercise-id').val(), score: isPerfect}, redirectNextTraining);
                }
                else{
                    
                    if (patternNo == "3" ){
                        $.get("/ajax_image_exercise", {work_id: currentWorkId, preview_mode: previewMode, pattern_no: patternNo, count: count, sub_count: subLoopCounter + 1, work_level: workLevel, work_history: workHistory}, function(){
                            loopInfo = JSON.parse($("#loop-info").val());
                            loopCounter = loopInfo.count;
                            subLoopCounter = loopInfo.sub_count;
                            //workId = loopInfo.sub_work_id;

                            // 読み込み画像のソースの配列を作成
                            const srcArry = [];
                            $(".image-situation-table img").each(function(){
                                srcArry.push($(this).attr("src"));
                            });
                            // コールバック関数に引数を渡す（第一引数はオブジェクトらしいが、未使用のためnull）
                            waitImageLoadedForAfterAjax(srcArry, no3Fitting.bind(null, subLoopCounter));
                            
                        
                        });
                    }
                    else
                        $.get("/ajax_image_exercise", {work_id: workId, preview_mode: previewMode, pattern_no: patternNo, count: loopCounter + 1, work_level: workLevel, work_history: workHistory}, function(){
                            
                            loopInfo = JSON.parse($("#loop-info").val());
                            loopCounter = loopInfo.count;

                            if(patternNo == "1" ){
                               // 読み込み画像のソースの配列を作成
                                const srcArry = [];
                                $(".image-situation-table img").each(function(){
                                    srcArry.push($(this).attr("src"));
                                });
                                waitImageLoadedForAfterAjax(srcArry, no1Fitting);
                            }

                            if(patternNo == "2" ){
                                // 読み込み画像のソースの配列を作成
                                const srcArry = [];
                                if($(".image-situation-table img").attr("src") !== undefined)
                                    srcArry.push($(".image-situation-table img").attr("src"));
                                waitImageLoadedForAfterAjax(srcArry, no2Fitting);
                            }

                        });
                }
            }
            else if( $("#modal-title-key").val() == "レベル変更") {
            // else if( $("#trainingModal .modal-title").html().indexOf("レベル") >= 0) {
                location.href = redirectUrl;
            }
        });
    }

});

function judge_answer(patternNo, judge_func){
    if(judge_func()) {
        //alert("正解");
        if (patternNo == "3" ){
            if(subLoopCounter + 1 == subLoopTotalCount){
                subLoopCounter = -1;
            }
        }
        modalShow("合格", "正解");
    }
    else{
        isPerfect = 0;
        modalShow("不合格", "不正");
    }
}