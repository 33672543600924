import {modalShow} from './../training_common';

export default function no2Func(){
    //console.log("javascript!");

    let classes = JSON.parse($("#combination-list").val());
    classes.push("");
    
    let $currentTd;

    let selectIndex;
    $(document).on("click", "#input-table td", function(){

        $currentTd = $(this);

        selectIndex = $("p", this).data("selection");
        if(selectIndex == undefined) selectIndex = 0;
        else selectIndex++ ;
        
        $("p", this).attr("class", "");
        
        $("p", this).addClass(classes[selectIndex]);
        if(selectIndex == classes.length - 1) selectIndex = -1;
        $("p", this).data("selection", selectIndex);
    });

    
    // 戻すボタン
    $(document).on("click", "#reverse-btn", function(){
        if( $currentTd !== undefined ){
            selectIndex = $("p", $currentTd).data("selection");
            if( selectIndex == -1 || selectIndex == 0 )selectIndex = - 1;
            else selectIndex--;

            $("p", $currentTd).attr("class", "");
            $("p", $currentTd).addClass(classes[selectIndex]);
            $("p", $currentTd).data("selection", selectIndex);
        }
    });

    $("#read_write-diff").on("click", function(){
        let allok = true;

        $("#input-table td").each(function(index){
            $(this).removeClass("miss");
        });

        let correct_list = [];
        $("#read-table td p").each(function(){
            correct_list.push($(this).data("selection"));
        });

        $("#input-table td p").each(function(index){
            
            if($(this).data("selection") != correct_list[index]){
                //console.log($(this).data("selection"), correct_list[index]);
                //console.log($(this));
                $(this).parent().addClass("miss");
                allok = false;
            }
        });

        if(allok == false){
            modalShow("不合格", "不正");
        }
        else{
            modalShow("合格", "正解");
        }

    });

}