import {modalShow} from '../training_common'
import {countDown} from './memory_common';

export default function no3Ball(){
    $("#memory-finish").toggle()
    // スタートボタンをクリック
    $(document).on('click',"#memory-start", function(){

        const disappearSecond = parseInt($("#disappear-second").val());

        // 記憶する表を表示
        $(".c-modal").removeClass("c-hidden");
        //$(".memory-no3-table").toggle();
        

        // スタートボタンを非表示
        $(this).parent().parent().toggle();
        countDown(disappearSecond * 1000);
        
        // 指定秒数後に、見本画像の非表示と、選択肢画像の表示
        setTimeout(function(){
            //$(".memory-no3-table").toggle();
            $(".c-modal").addClass("c-hidden");
            $(".memory-no3-table-answer").toggle();
            $("#memory-finish").toggle();
            //$(".memory-selection .selection").toggle();
        }, disappearSecond * 1000);
    });

    // セレクトオプションにCSSを有効にさせるbootstrap-selectにおいて、
    // 選択後の値のセンタリング
    $(document).on('changed.bs.select', function(){
        $("div.filter-option").css("text-align", "center");
    });

    // セレクトオプションにCSSを有効にさせるbootstrap-selectにおいて、
    // 選択前の初期値のセンタリング
    $(document).on('loaded.bs.select', function(){
        $("div.filter-option").css("text-align", "center");
    });

    // 選択肢画像をクリックしたときの動作
    $(document).on('click', '#memory-finish', function(){
        $(".memory-no3-table-answer .dropdown").each(function(){
            $(this).removeClass("miss");
        });
        let allok = true;
        $(".memory-no3-table-answer .selectpicker").each(function(){
            // console.log( $(this).data("correct") );
            // console.log( $(this).val() );
            if($(this).data("correct") != $(this).val()){
                allok = false;
                const $dropdown = $(this).parent();
                $dropdown.addClass("miss");
            }
        });

        if(allok == false){
            modalShow("不合格", "不正");
        }
        else{
            modalShow("合格", "正解");
        }
    });

}
