import {modalShow} from '../training_common'

export default function no1_no8Func(){
    $("#btn-attention-finish").on('click', function(){
        // missクラスを削除
        $(".attention-table select.miss").each(function(){
            $(this).removeClass("miss");
        });

        // answers配列に回答を格納
        const answers = [];
        $(".attention-table [name=answer]").each(function(){
            // $(this)はselect
            answers.push(parseInt($(this).val()));
        });
        
        //const correct_answers = gon.numbers.slice(gon.count * 9, (gon.count + 1) * 9) ;
        const correct_answers = JSON.parse($("#answers-array").val());
        

        // 回答と正解（gonで提供）で照合
        const result = array_equal(answers, correct_answers);
        // 配列が空であれば合格
        if(!result.length){
            modalShow("合格", "正解");
        } 
        // 誤り回答のプルダウンにmissクラスを付与
        else {
            $.each(result, function(i, num){
                //var p1 = Math.floor(num / 9) ;
                const p2 = Math.floor((num % 9) / 3) ;
                const p3 = num % 9 % 3 ;
                const id_name = '#answer-' + p2 + '-' + p3;
                // select要素にmissクラスを付与
                $(id_name).addClass("miss");
            });
            //isPerfect = 0;
            modalShow("不合格", "不正");
        }
    });
}



function array_equal(a, b) {
    if (!Array.isArray(a))    return false;
    if (!Array.isArray(b))    return false;
    const miss = [];
    for (var i = 0, n = a.length; i < n; ++i) {
        if (a[i] !== b[i]) miss.push(i)
    }
    return miss;
}