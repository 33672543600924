import {modalShow} from './../training_common'
import {pastSec, startTimer, stopTimer, displayTimeOnModal } from './eye_common';

export default function no2No3Func(includedCount){

    $(".eye-table a").on('click', function(){
        if(pastSec == 0) startTimer(0);
        $(this).toggleClass('circle');
    });
    $("#btn-eye-count").on('click', function(){
        const training_result = $("a.target.circle").length;
        const training_result_circle = $("a.circle").length;

        if(includedCount == training_result){
            if(training_result == training_result_circle){
                stopTimer();
                modalShow("合格", "正解");
                displayTimeOnModal();
            }
            else{
                stopTimer();
                modalShow("不合格", "過剰");
            }
        }
        else{
            stopTimer();
            modalShow("不合格", "不足");
        }
    });
}