import {modalShow} from './../training_common'
import anime from 'animejs'

let roundCount = 10; // 5往復

export default function no6Func(){

    $("#moving-object").width($(".eye-main-border").width() / 8);

    const direction = $("#direction").val();//gon.direction;
    let animeLength;
    let scale;
    if(direction == 0 || direction == 2){ //横移動
        animeLength = $(".eye-main-border").width() - $("#moving-object").width() ;        
    }
    else if(direction == 1 || direction == 3){ //縦移動
        animeLength = $(".eye-main-border").height() - $("#moving-object").height() ;
    }
    else if(direction == 4){ // 対角線移動
        animeLength = [$(".eye-main-border").width() - $("#moving-object").width(), $(".eye-main-border").height() - $("#moving-object").height()] ;
    }else { //拡大
        // 画像を中央に配置
        $("#moving-object").offset({top: $(".eye-main-border").offset().top + $(".eye-main-border").height()/2 - $("#moving-object").height()/2});
        scale = $(".eye-main-border").height()/$("#moving-object").height();
    }
    
    //　始めるボタンを非表示化 
    $("#btn-eye-start").on('click', function(){
        movingStart(animeLength, direction, scale);
        $(this).css("visibility", "hidden");
    });

}

function movingStart(animeLength, direction, scale){
    const speedParam = 1000;
    
    const params = {
        targets: "#moving-object",
        loop: true,
        easing: 'linear',
        direction: 'alternate',
        
        loopComplete: function(anim) {
            roundCount--;
            if(roundCount == 0){
                anim.pause();
                modalShow("合格", "正解");
            } 

        },
        duration: speedParam
    };

    if(direction == 0 || direction == 2) params.translateX = animeLength;
    else if(direction == 1 || direction == 3) params.translateY = animeLength;
    else if(direction == 4){
        params.translateX = animeLength[0];
        params.translateY = animeLength[1];
    }
    

    if(direction == 5){
        anime.timeline(params).add({scale: scale}).add({scale: 0.1});
    }
    else
        anime(params);
}
