/* コンテンツのフォントサイズ調整 for no1, no2 */
export function autoFontAdjust(patternNo, level){
    let resolution;
    if(patternNo == 3)
        resolution = 1;
    else if(level == "high"){
        resolution = 0.5;
    }
    else{
        resolution = 1;
    }

    const contentsAreaHeight = $(".eye-main-border").height();
    let $adjustedArea = $(".eye-table-wrap");
    // 無限ループ回避用
    let previousHeight = -1;
    let sameHeightCounter = 0;

    if( contentsAreaHeight < $adjustedArea.height() ){
        while(1){

            if( (patternNo == 1 || patternNo == 2) && level == "high"){    
                $(".eye-table td").each(function(){
                    const fontsize = parseFloat($(this).css("font-size").slice(0, -2)) - resolution;
                    if(fontsize > 0){
                        $(this).css("font-size", fontsize);
                    }
                });
            }
            else{
                const fontsize = parseFloat($(".eye-table td:first").css("font-size").slice(0, -2)) - resolution;
                if(fontsize > 0){
                    $(".eye-table td").css("font-size", fontsize);
                }
            }

            const adjustedAreaHeight = $adjustedArea.height(); 
            //console.log(contentsAreaHeight, adjustedAreaHeight, sameHeightCounter);
            // テーブルのサイズが、外枠よりも若干小さくならない現象があった(no2-highの問題で、chromeで839x735のサイズで生じた)
            // そのため、高さの99%とした。
            if( contentsAreaHeight >= adjustedAreaHeight * 0.99 ){
                break;
            }

            // 以下、無限ループ回避用
            if(previousHeight != adjustedAreaHeight){
                previousHeight = adjustedAreaHeight;
                sameHeightCounter = 0;
            }
            else{
                sameHeightCounter++;
                
            }

            if(sameHeightCounter == 100){
                break;
            }

        }
    }
}

export let pastSec = 0;
let timerId;
export function startTimer(offset){
    
    if(offset == 0) turbolinksSetting();

    const oldTime = Date.now();

    timerId = setInterval(() => {
        const currentTime = Date.now();

        // 差分を求める
        const diff = currentTime - oldTime;
            
        //ミリ秒を整数に変換
        pastSec = Math.ceil(diff / 1000) + offset;

    /*
    // 0秒以下になったら
    if (diffSec <= 0) {
        clearInterval(timeId);

        // タイマー終了の文言を表示する
        text = "どのイラストが<ruby><rb>表示</rb><rt>ひょうじ</rt><ruby>されていたかな？";
    }
    */
    
        // 画面に表示する
        $('#timer').html(pastSec);
    });

    
}

function turbolinksSetting(){
    // ターボリンクスの仕様でページ遷移後にタイマーが残るため、ページ遷移前にタイマーを消去する設定
    $(document).on('turbolinks:before-cache turbolinks:before-render', function() {
        startTimer();
        //clearTimeout(timerId);
    });
}

export function stopTimer(){
    clearInterval(timerId);
}


export function displayTimeOnModal(){
    $("#trainingModal .modal-body").append("<p class='pl-4'>タイム：" + pastSec + "<ruby><rb>秒</rb><rt>びょう</rt></ruby></p>");
}