import {modalShow} from './../training_common';
var canvas2;
var canvas;
var startX;
var startY;
var endX;
var endY;
var startFlag = false;
var lineCount = 0;
var history = new Array();
var fixed_point = [];
let line;
var points;
var eraseMode = false;

var background_image_url;
var payload ;
var generalData;

// 生徒データを４種類の線に分け、合成できる線をまとめて格納する配列
let horizon;
let vertical;
let upRight;
let downRight; 

export function no1Func(canvasId="student_canvas", canvas2Id="teacher_canvas", loadedCanvasId="teacher_canvas"){
    setCanvas(canvasId, canvas2Id, loadedCanvasId);

    $("#read_write-undo").on('click', function(){
        undo();
    });

    $("#read_write-erase").on('click', function(){
        erase();
    });

    $("#read_write-allclear").on('click', function(){
        canvas.clear();
        fabric.loadSVGFromURL(background_image_url, function(objects, options){
            var svgGroups = fabric.util.groupSVGElements(objects, options);
            svgGroups.scaleToHeight(canvas.height);
            svgGroups.scaleToWidth(canvas.width);
            canvas.setBackgroundImage(svgGroups, canvas.renderAll.bind(canvas));
        });
    });

    // admin-デバッグ用
    $("#read_write-save-debug").on('click', function(){
        var json = JSON.stringify(canvas);
        //console.log(json);
    });

    // admin-save用
    $("#read_write-save").on('click', function(){
        saveData(canvas).then(function(val){
            //console.log(val);
        });
    });

    
    // 合否判定処理
    $("#read_write-diff").on('click', function(){
        horizon = [];
        vertical = [];
        upRight = [];
        downRight = []; 

        canvas.forEachObject(function(obj){
            if( obj.width == 0 && obj.height == 0) return ;
            if(obj.type != "line") return;

            const kind = vectorJudge(obj);
            let rect;
                    
            let fbPoint1 = near_point(obj.x1, obj.y1, true);
            let fbPoint2 = near_point(obj.x2, obj.y2, true);

            if(kind == 1){
                // 右向きの線に統一
                if(obj.x1 < obj.x2){
                    rect = [fbPoint1, fbPoint2] ;
                } 
                else{
                    rect = [fbPoint2, fbPoint1] ;
                }
                horizon.push(rect);
                collectionVector(horizon);
                
            } 
            
            if(kind == 2){
                // 下から上方向に統一
                if(obj.y1 < obj.y2){
                    rect = [fbPoint1, fbPoint2] ;
                } 
                else{
                    rect = [fbPoint2, fbPoint1] ;
                }
                
                vertical.push(rect);
                collectionVector(vertical);
                
            }

            if(kind == 3){
                // 方向を下から上方向に統一
                if(obj.y1 < obj.y2){
                    rect = [fbPoint1, fbPoint2] ;
                } 
                else{
                    rect = [fbPoint2, fbPoint1] ;
                }
                
                upRight.push(rect);
                collectionVector(upRight);
            }

            if(kind == 4){
                // 方向を左から右方向に統一
                if(obj.x1 < obj.x2){
                    rect = [fbPoint1, fbPoint2] ;
                } 
                else{
                    rect = [fbPoint2, fbPoint1] ;
                }
                
                downRight.push(rect);
                collectionVector(downRight);
                
            }
        });

        horizon.sort();
        vertical.sort();
        upRight.sort();
        downRight.sort();

        // console.log(horizon.length);
        // console.log(vertical.length);
        // console.log(upRight.length);
        // console.log(downRight.length);

        let okflag = true;

        if(horizon.length == t_horizon.length){
            let i ;
            for(i = 0 ; i < horizon.length; i++){
                if( !(horizon[i][0].eq(t_horizon[i][0]) && horizon[i][1].eq(t_horizon[i][1]))){
                    break;
                }
            }
            if(i != horizon.length){
                //console.log("horizon vector ng");
                okflag = false;
            }
            else{
                //console.log("horizon ok");
            }
        }else{
            //console.log("horizon length ng: " + horizon.length + "/" + t_horizon.length);
            okflag = false;
        }

        if(vertical.length == t_vertical.length){
            let i ;
            for(i = 0 ; i < vertical.length; i++){
                if( !(vertical[i][0].eq(t_vertical[i][0]) && vertical[i][1].eq(t_vertical[i][1]))){
                    break;
                }
            }
            if(i != vertical.length){
                //console.log("vertical vector ng");
                okflag = false;
            }
            else{
                //console.log("vertical ok");
            }
        }else{
            //console.log("vertical length ng: " + vertical.length + "/" + t_vertical.length);
            okflag = false;
        }

        if(upRight.length == t_upRight.length){
            let i ;
            for(i = 0 ; i < upRight.length; i++){
                if( !(upRight[i][0].eq(t_upRight[i][0]) && upRight[i][1].eq(t_upRight[i][1]))){
                    break;
                }
            }
            if(i != upRight.length){
                //console.log("upRight vector ng");
                okflag = false;
            }
            else{
                //console.log("upRight ok");
            }
        }else{
            //console.log("upRight length ng: " + upRight.length + "/" + t_upRight.length);
            okflag = false;
        }

        if(downRight.length == t_downRight.length){
            let i ;
            for(i = 0 ; i < downRight.length; i++){
                if( !(downRight[i][0].eq(t_downRight[i][0]) && downRight[i][1].eq(t_downRight[i][1]))){
                    break;
                }
            }
            if(i != downRight.length){
                //console.log("downRight vector ng");
                okflag = false;
            }
            else{
                //console.log("downRight ok");
            }
        }else{
            //console.log("downRight length ng: " + downRight.length + "/" + t_downRight.length);
            okflag = false;
        }

        if(okflag){
            modalShow("合格", "正解");
        }
        else {
            modalShow("不合格", "不正");
        }
        
    });

}


// 保存データが大きいため、非同期処理で完了を待った後にsubmitする。
async function saveData(canvas){
    let resolve = await new Promise(function(resolve){
                    canvas.forEachObject(function(obj) {
                        if(obj.type != "line") canvas.remove(obj);
                    });
                    var json = JSON.stringify(canvas.renderAll());
                    $("#work_pictures_attributes_0_payload").val(json);
                    $("#work_pictures_attributes_0_general_data").val(
                        JSON.stringify({
                            height: canvas.height
                        })
                    );
                    resolve("save Done!");
                });
    return resolve;
}

let canvasScale = 1;
export function setCanvas(canvasId, canvas2Id, loadedCanvasId){
    
    var temp = [];
    let metadata = -1;
    
    let originalCanvasLength ;
    
    background_image_url = $("#read_write-background_image_url").val(); 
    payload = $("#read_write-payload").val(); 
    generalData = $("#read_write-general-data").val(); 


    const backgroundImagePixelWidth = 500.5;
    
    
    canvas = new fabric.Canvas(canvasId);

    // admin画面時の領域確保
    if(loadedCanvasId == canvasId) {
        $(".teacher-area").width($("#adminModal").width()/3);
        $(".teacher-area").height($("#adminModal").width()/3);
    }
    else{
        // キャンバスサイズについて、まず高さを基準に設定する。
        // 次に、その高さで正方形にした場合に、横幅が画面幅を超えていれば調整する。
        let canvasSize = $(".fb-main-contents-height").height() * 0.8;
        let maxSize = $(".fb-main-contents-height").width()/2;
        if(  maxSize < canvasSize ){
            canvasSize = maxSize - maxSize * 0.05;
        }
        $(".teacher-area").height(canvasSize );
        $(".teacher-area").width(canvasSize );

    }

    if(generalData !== undefined && generalData != "" ){
        metadata = JSON.parse(generalData);
        originalCanvasLength = metadata.height;
    }
    else{
        originalCanvasLength = $(".teacher-area").height(); 
    }
    
    let canvasLength;
    if(loadedCanvasId == canvasId){
        canvasLength = originalCanvasLength
    }
    else{
        canvasLength = $(".teacher-area").height();
    }
        
    canvas.setWidth(canvasLength);
    canvas.setHeight(canvasLength);

    // 本番でキャンバスがセンタリングできないため、強制的に移動
    if($("#canvas-wrapper").length){
        $("#canvas-wrapper").offset({left: $("#canvas-wrapper").offset().left + ($(".teacher-area").width() - canvas.width)/2});
    }
    
    
    canvasScale = canvas.height / originalCanvasLength ;
    
    //console.log(canvasScale);

    // 保存データがあればlineデータを読み出す。
    if(loadedCanvasId == canvasId) {
        canvas.loadFromJSON(payload, canvas.renderAll.bind(canvas));
        if(canvasScale != 1){
            canvas.forEachObject(function(obj) {
                if(obj.type == "line"){
                    //console.log(obj);
                    obj.scale(canvasScale).set({
                        top: obj.top * canvasScale,
                        left: obj.left * canvasScale
                    });
                    //console.log(obj);
                }        
            });
            canvas.renderAll();
        }
    }

    // 背景画像は必ず読み込む。
    fabric.loadSVGFromURL(background_image_url, function(objects, options){
        var svgGroups = fabric.util.groupSVGElements(objects, options);
        svgGroups.scaleToHeight(canvas.height);
        svgGroups.scaleToWidth(canvas.width);
        canvas.setBackgroundImage(svgGroups, canvas.renderAll.bind(canvas));
    });
    
    // ドットの座標をSVGから抽出して、オリジナルから拡大した分だけスケールした座標を求める。
    points = [];
    $.each($("#read_write-points").val().split(" "), function(loop, point){
        if(loop % 2 == 0){
            temp = [];
            temp.push(parseFloat(point) * originalCanvasLength/backgroundImagePixelWidth * canvasScale );
        }
        else{
            temp.push(parseFloat(point) * originalCanvasLength/backgroundImagePixelWidth * canvasScale );
            points.push(temp);
        }
    });
    //console.log(points);
    

    // 管理画面では使用しない。本番では教師canvasの描画を担当
    if(loadedCanvasId == canvas2Id){
        canvas2 = new fabric.StaticCanvas(canvas2Id);
        canvas2.setWidth(canvasLength);
        canvas2.setHeight(canvasLength);
    
        canvas2.loadFromJSON(payload, canvas2.renderAll.bind(canvas2), function(json, obj){
            if(canvasScale != 1){
                if(obj.type == "line"){
                    obj.scale(canvasScale).set({
                        top: obj.top * canvasScale,
                        left: obj.left * canvasScale
                    });
                   //console.log(obj);
                }        
            }
        });
        
        fabric.loadSVGFromURL(background_image_url, function(objects, options){
            var svgGroups = fabric.util.groupSVGElements(objects, options);
            svgGroups.scaleToHeight(canvas2.height);
            svgGroups.scaleToWidth(canvas2.width);
            canvas2.setBackgroundImage(svgGroups, canvas2.renderAll.bind(canvas2), {
                left: (canvas2.width - svgGroups.width * svgGroups.scaleX) / 2
            });
        });

        practiceJudge();
    }
    

    canvas.isDrawingMode = false;
    
    canvas.on('mouse:down', function(options){
        if(!eraseMode)
            mouseDownListner(options.pointer);
        // else{
        //     //options.target.toCanvasElement({withoutTransform: true});
        // }
    });
    
    canvas.on('mouse:up', function(options){
        if(eraseMode && options.target != null){
            //if(confirm("けしますか？")){
                canvas.remove(options.target);
                canvas.renderAll();
            //}
        }
        else
            mouseUpListner();
    });

    canvas.on('object:moving', function(){
        return false;
        //console.log("moving");
    });

    canvas.on('mouse:move', function(options){
        mouseMoveListner(options.pointer);
    });

}


// canvasのonload時にstartFlag=trueを設定しておきます。
function mouseDownListner(pointer){
    startFlag = true;
    //既存の直線の数を格納しておく
    lineCount = canvas._objects.length ;
    //始点決定（始点、終点座標はテキストボックスに表示されているとします）
    //console.log('(' + pointer.x + ',' + pointer.y + ')');
    fixed_point = near_point(pointer.x, pointer.y);
    
    startX = fixed_point[0]; //document.getElementById('startXTextId').value;
    startY = fixed_point[1]; //document.getElementById('startYTextId').value;
    
    //DrawingModeをtrueにします
    canvas.isDrawingMode = true;
}

function mouseUpListner(){
    startFlag = false;
    canvas.isDrawingMode = false;

    if(line == undefined) return;

    canvas.remove(canvas._objects.pop());
    
    const lineKind = vectorJudge(line);
    const lineTLx = Math.round(line.aCoords.tl.x);
    const lineTLy = Math.round(line.aCoords.tl.y);
    const lineTRx = Math.round(line.aCoords.tr.x);
    const lineTRy = Math.round(line.aCoords.tr.y);
    const lineBLy = Math.round(line.aCoords.bl.y);
    const lineBLx = Math.round(line.aCoords.bl.x);
    const lineBRx = Math.round(line.aCoords.br.x);
    
    let uniqFlag = true;
    canvas.forEachObject(function(obj){
        if(obj.type != "line") return;
        if(vectorJudge(obj) != lineKind) return;
        // 必要
        if(!line.intersectsWithObject(obj)) return;

        // 左右の線
        if(lineKind == 1){ 
            //console.log("左右");           
            const objTL = Math.round(obj.aCoords.tl.x);
            const objTR = Math.round(obj.aCoords.tr.x);
            if(objTL <= lineTLx && objTR >= lineTRx){
                //console.log("包含 line削除");
                uniqFlag = false;
                return false;
            }

            if(objTL >= lineTLx && objTR <= lineTRx){
                //console.log("包含 obj削除");
                canvas.remove(obj);
                return false;
            }

            if(objTR == lineTLx || objTL == lineTRx){
                //console.log("nothing");
                return false;
            }

            if(objTL < lineTLx && lineTLx < objTR || lineTLx < objTL && objTL < lineTRx){
                //console.log("クロス line削除 obj削除 新obj追加");
                uniqFlag = false;
                canvas.remove(obj);
                if(objTL < lineTLx){
                    startX = obj.aCoords.tl.x;
                    startY = obj.aCoords.tl.y;
                    endX = line.aCoords.tr.x;
                    endY = line.aCoords.tr.y;
                }
                else{
                    startX = line.aCoords.tl.x;
                    startY = line.aCoords.tl.y;
                    endX = obj.aCoords.tr.x;
                    endY = obj.aCoords.tr.y;
                }

                drawLine()
                return false;
            }

        }

        if(lineKind == 2){     
            //console.log("上下");                  
            const objTL = Math.round(obj.aCoords.tl.y);
            const objBL = Math.round(obj.aCoords.bl.y);
            if(objBL >= lineBLy && objTL <= lineTLy){
                //console.log("包含 line削除");
                uniqFlag = false;
                return false;
            }

            if(objBL <= lineBLy && objTL >= lineTLy){
                //console.log("包含 obj削除");
                canvas.remove(obj);
                return false;
            }

            if(objBL == lineTLy || objTL == lineBLy){
                //console.log("nothing");
                return false;
            }
            
            if(objBL > lineBLy && lineBLy > objTL || lineBLy > objBL && objBL > lineTLy){
                //console.log("クロス line削除 obj削除 新obj追加");
                uniqFlag = false;
                canvas.remove(obj);
                if(objBL > lineBLy){
                    startX = obj.aCoords.bl.x;
                    startY = obj.aCoords.bl.y;
                    endX = line.aCoords.tl.x;
                    endY = line.aCoords.tl.y;
                }
                else{
                    startX = line.aCoords.bl.x;
                    startY = line.aCoords.bl.y;
                    endX = obj.aCoords.tl.x;
                    endY = obj.aCoords.tl.y;
                }

                drawLine()
                return false;
            }
        }

        if(lineKind == 3){
            //console.log("右肩あがり");
            // const objBLx = Math.round(obj.aCoords.bl.x);
            // const objBLy = Math.round(obj.aCoords.bl.y);
            // const objTRx = Math.round(obj.aCoords.tr.x);
            // const objTRy = Math.round(obj.aCoords.tr.y);
            
            const objPointTR = near_point(obj.aCoords.tr.x, obj.aCoords.tr.y, true);
            const linePointBL = near_point(line.aCoords.bl.x, line.aCoords.bl.y, true);
            const objPointBL = near_point(obj.aCoords.bl.x, obj.aCoords.bl.y, true);
            const linePointTR = near_point(line.aCoords.tr.x, line.aCoords.tr.y, true);

            // console.log(objPointTR, objPointBL);
            // console.log(linePointTR, linePointBL);

            // obj, lineが同一線上にあること
            // 直線AB, CDが同一直線状であれば、ABの角度と、AC, ADの角度は等しい
            const baseRadianAB = Math.round(Math.atan2(objPointBL.y - objPointTR.y, objPointTR.x - objPointBL.x) * Math.pow(10, 3))/ Math.pow(10, 3);
            let radianAC ;
            let radianAD ;

            // ACBD
            if(objPointBL.x <= linePointBL.x){
                radianAC = Math.round(Math.atan2(objPointBL.y - linePointBL.y, linePointBL.x - objPointBL.x) * Math.pow(10, 3))/ Math.pow(10, 3);
                radianAD = Math.round(Math.atan2(objPointBL.y - linePointTR.y, linePointTR.x - objPointBL.x) * Math.pow(10, 3))/ Math.pow(10, 3);
                //console.log("a");
            }
            // CADB
            else if( linePointBL.x < objPointBL.x && objPointBL.x < linePointTR.x){
                //radianAC = Math.round(Math.atan2(objPointBL.x - linePointBL.x, linePointBL.y - objPointBL.y) * Math.pow(10, 3))/ Math.pow(10, 3);
                radianAC = Math.round(Math.atan2(linePointBL.y - objPointBL.y, objPointBL.x - linePointBL.x, ) * Math.pow(10, 3))/ Math.pow(10, 3);
                //radianAD = Math.round(Math.atan2(objPointBL.y - linePointTR.y, linePointTR.x - objPointBL.x) * Math.pow(10, 3))/ Math.pow(10, 3);
                radianAD = Math.round(Math.atan2(objPointBL.y - linePointTR.y, linePointTR.x - objPointBL.x) * Math.pow(10, 3))/ Math.pow(10, 3);
                //console.log("b");
            }
            // CDAB
            else{
                //radianAC = Math.round(Math.atan2(objPointBL.x - linePointBL.x, linePointBL.y - objPointBL.y) * Math.pow(10, 3))/ Math.pow(10, 3);
                radianAC = Math.round(Math.atan2(linePointBL.y - objPointBL.y, objPointBL.x - linePointBL.x) * Math.pow(10, 3))/ Math.pow(10, 3);
                //radianAD = Math.round(Math.atan2(objPointBL.x - linePointTR.x, linePointTR.y - objPointBL.y) * Math.pow(10, 3))/ Math.pow(10, 3);
                radianAD = Math.round(Math.atan2(linePointTR.y - objPointBL.y, objPointBL.x - linePointTR.x) * Math.pow(10, 3))/ Math.pow(10, 3);
                //console.log("c");
            }
            //console.log(baseRadianAB, radianAC, radianAD);
            // if(radianAC == 0) radianAC = baseRadianAB;
            // if(radianAD == 0) radianAD = baseRadianAB;

            //console.log(baseRadianAB, radianAC, radianAD, Math.abs(baseRadianAB - radianAC), Math.abs(baseRadianAB - radianAD));
            if(Math.abs(baseRadianAB - radianAC) > 0.02 || Math.abs(baseRadianAB - radianAD) > 0.02){
                return ;
            }
            //console.log("同一直線")

            //if(objBLx <= lineBLx && objTRx >= lineTRx){ //&& objBLy >= lineBLy && objTRy <= lineTRy){
            if(objPointBL.x <= linePointBL.x && objPointTR.x >= linePointTR.x){ //&& objBLy >= lineBLy && objTRy <= lineTRy){
                //console.log("包含 line削除");
                uniqFlag = false;
                return false;
            }

            //if(objBLx >= lineBLx && objTRx <= lineTRx){// && objBLy <= lineBLy && objTRy >= lineTLy){
            if(objPointBL.x >= linePointBL.x && objPointTR.x <= linePointTR.x){// && objBLy <= lineBLy && objTRy >= lineTLy){
                //console.log("包含 obj削除");
                canvas.remove(obj);
                return false;
            }

            if(objPointTR.eq(linePointBL) || objPointBL.eq(linePointTR)){
                //console.log("nothing");
                return false;
            }
            

            //if(objBLx < lineBLx && lineBLx < objTRx || lineBLx < objBLx && objBLx < lineTRx){
            if(objPointBL.x < linePointBL.x && linePointBL.x < objPointTR.x || linePointBL.x < objPointBL.x && objPointBL.x < linePointTR.x){
                //console.log("クロス line削除 obj削除 新obj追加");
                uniqFlag = false;
                canvas.remove(obj);
                if(objPointBL.x < linePointBL.x){
                    startX = objPointBL.x;
                    startY = objPointBL.y;
                    endX = linePointTR.x
                    endY = linePointTR.y
                }
                else{
                    startX = linePointBL.x;
                    startY = linePointBL.y;
                    endX = objPointTR.x;
                    endY = objPointTR.y;
                }

                drawLine()
                return false;
            }
            

        }

        if(lineKind == 4){
            //console.log("右肩さがり");

            // const objTLx = Math.round(obj.aCoords.tl.x);
            // const objBRx = Math.round(obj.aCoords.br.x);
            
            const objPointBR = near_point(obj.aCoords.br.x, obj.aCoords.br.y, true);
            const linePointTL = near_point(line.aCoords.tl.x, line.aCoords.tl.y, true);
            const objPointTL = near_point(obj.aCoords.tl.x, obj.aCoords.tl.y, true);
            const linePointBR = near_point(line.aCoords.br.x, line.aCoords.br.y, true);

            // obj, lineが同一線上にあること
            // 直線AB, CDが同一直線状であれば、ABの角度と、AC, ADの角度は等しい
            const baseRadianAB = Math.round(Math.atan2(objPointBR.y - objPointTL.y, objPointBR.x - objPointTL.x) * Math.pow(10, 3))/ Math.pow(10, 3);
            
            let radianAC ;
            let radianAD ;
            // ACBD
            if(objPointTL.x <= linePointTL.x){
                radianAC = Math.round(Math.atan2(linePointTL.y - objPointTL.y, linePointTL.x - objPointTL.x) * Math.pow(10, 3))/ Math.pow(10, 3);
                radianAD = Math.round(Math.atan2(linePointBR.y - objPointTL.y, linePointBR.x - objPointTL.x) * Math.pow(10, 3))/ Math.pow(10, 3);
                //console.log("a");
            }
            // CADB
            else if( linePointTL.x < objPointTL.x && objPointTL.x < linePointBR.x){
                //radianAC = Math.round(Math.atan2(objPointTL.x - linePointTL.x, objPointTL.y - linePointTL.y) * Math.pow(10, 3))/ Math.pow(10, 3);
                radianAC = Math.round(Math.atan2(objPointTL.y - linePointTL.y, objPointTL.x - linePointTL.x) * Math.pow(10, 3))/ Math.pow(10, 3);
                radianAD = Math.round(Math.atan2(linePointBR.y - objPointTL.y, linePointBR.x - objPointTL.x) * Math.pow(10, 3))/ Math.pow(10, 3);
                //console.log("b");
            }
            // CDAB
            else{
                //radianAC = Math.round(Math.atan2(objPointTL.x - linePointTL.x, objPointTL.y - linePointTL.y) * Math.pow(10, 3))/ Math.pow(10, 3);
                radianAC = Math.round(Math.atan2(objPointTL.y - linePointTL.y, objPointTL.x - linePointTL.x) * Math.pow(10, 3))/ Math.pow(10, 3);
                //radianAD = Math.round(Math.atan2(objPointTL.x - linePointBR.x, objPointTL.y - linePointBR.y) * Math.pow(10, 3))/ Math.pow(10, 3);
                radianAD = Math.round(Math.atan2(objPointTL.y - linePointBR.y, objPointTL.x - linePointBR.x ) * Math.pow(10, 3))/ Math.pow(10, 3);
                //console.log("c");
            }
            
            // if(radianAC == 0) radianAC = baseRadianAB;
            // if(radianAD == 0) radianAD = baseRadianAB;

            //console.log(baseRadianAB, radianAC, radianAD, Math.abs(baseRadianAB - radianAC), Math.abs(baseRadianAB - radianAD));
            if(Math.abs(baseRadianAB - radianAC) > 0.02 || Math.abs(baseRadianAB - radianAD) > 0.02){
                return ;
            }
            //console.log("同一直線")

            if(objPointTL.x <= linePointTL.x && objPointBR.x >= linePointBR.x){
            //if(objTLx <= lineTLx && objBRx >= lineBRx){
                //console.log("包含 line削除");
                uniqFlag = false;
                return false;
            }
            if(objPointTL.x >= linePointTL.x && objPointBR.x <= linePointBR.x){
            //if(objTLx >= lineTLx && objBRx <= lineBRx){
                //console.log("包含 obj削除");
                canvas.remove(obj);
                return false;
            }

            //console.log(obj,line);
            //console.log(objBRx, lineTLx, objTLx, lineBRx)
            
            if(objPointBR.eq(linePointTL) || objPointTL.eq(linePointBR)){
                //console.log("nothing");
                return false;
            }
            
            if(objPointTL.x < linePointTL.x && linePointTL.x < objPointBR.x || linePointTL.x < objPointTL.x && objPointTL.x < linePointBR.x){
            //if(objTLx < lineTLx && lineTLx < objBRx || lineTLx < objTLx && objTLx < lineBLx){
                //console.log("クロス line削除 obj削除 新obj追加");
                uniqFlag = false;
                canvas.remove(obj);
                if(objPointTL.x < linePointTL.x){
                    startX = objPointTL.x;
                    startY = objPointTL.y;
                    endX = linePointBR.x;
                    endY = linePointBR.y;
                }
                else{
                    startX = linePointTL.x; 
                    startY = linePointTL.y; 
                    endX = objPointBR.x;
                    endY = objPointBR.y;
                }

                drawLine()
                return false;
            }

        }
        
    });

    if(uniqFlag) canvas.add(line);
    
    //zahyo();
}

// 軌跡を書く
function mouseMoveListner(pointer){

    if(startFlag == true){
        //前の線(軌跡)を消す
        if(canvas._objects.length == lineCount + 1)
            canvas.remove(canvas._objects.pop());
        //終点座標を求める
        fixed_point = near_point(pointer.x, pointer.y);
        endX = fixed_point[0];
        endY = fixed_point[1];
        //軌跡を描く
        drawLine();
    }
}
const strokeWidth = 4;
function drawLine(){
    line = new fabric.Line([ Number(startX), Number(startY), Number(endX), Number(endY) ],
    {
        stroke: 'blue',
        strokeWidth: strokeWidth * canvasScale,
        selectable: false,


        //hasControls: false
    }
    );

    canvas.add(line);
}

// 戻る
function undo(){
    if(canvas._objects.length > 0){
        history.push(canvas._objects.pop());
        canvas.renderAll();
    }
}

// 進む
function redo(){
    if(history.length > 0)
        canvas.add(history.pop());
}

// 消しゴムモード
let btnText ;
function erase(){
    
    if(eraseMode){
        eraseMode = false;

        // $.each(canvas._objects, function(index, value){
        //     value["selectable"] = false;
        // });
        $("#read_write-erase").html(btnText);
        $("#read_write-erase").removeClass("btn-warning");
    }
    else {
        eraseMode = true;
        // $.each(canvas._objects, function(index, value){
        //     value["selectable"] = true;
        // });
        btnText = $("#read_write-erase").html();
        $("#read_write-erase").html('<i class="fas fa-pencil-alt"></i>' + "<ruby><rb>線</rb><rt>せん</rt></ruby>を<ruby><rb>引</rb><rt>ひ</rt></ruby>く");
        $("#read_write-erase").addClass("btn-warning");
    }
    //console.log(canvas.getActiveObjects());
    //canvas.getActiveObject().remove();
}

function zahyo(){
    console.log("(" + startX + "," + startY + ")");
    console.log("(" + endX + "," + endY + ")");
}

function near_point(x, y, fabricPoint = false){
    var temp = [];
    $.each(points, function(index, val){
        temp.push(Math.pow(Math.abs(val[0] - x), 2) + Math.pow(Math.abs(val[1] - y), 2));
    });
    const aryMin = function (a, b) {return Math.min(a, b);}
    //console.log(temp);
    //console.log(temp.indexOf(temp.reduce(aryMin)));
    //console.log(points[temp.indexOf(temp.reduce(aryMin))]);
    const fixed_point = points[ temp.indexOf(temp.reduce(aryMin)) ];
    if( fabricPoint ){
        return new fabric.Point(fixed_point[0] - strokeWidth/2, fixed_point[1] - strokeWidth/2);
    }
    else{
        return [fixed_point[0] - strokeWidth/2, fixed_point[1] - strokeWidth/2];
    }
    
}


// 見本データを4種類の線に分けて、合成できる線をまとめる配列
let t_horizon;
let t_vertical;
let t_upRight;
let t_downRight; 

function practiceJudge(){
    t_horizon = [];
    t_vertical = [];
    t_upRight = [];
    t_downRight = [];

    canvas2.forEachObject(function(obj) {
        if(obj.width == 0 && obj.height == 0) return;
        if(obj.type != "line") return;

        // 線を、横線、縦線、右上がり、右下がりの４種に分別（それぞれ、1,2,3,4のkind番号でグループ分け）
        const kind = vectorJudge(obj);
        
        // 線の始点と終点をrectとして捉える。
        let rect;

        // 右上がりを除き、３種類はこれでOK（top-left, bottom-rightの２点の座標）
        let fbPoint1 = near_point(obj.lineCoords.tl.x, obj.lineCoords.tl.y, true);
        let fbPoint2 = near_point(obj.lineCoords.br.x, obj.lineCoords.br.y, true);

        // 横線の場合
        if(kind == 1){
            // 描画方向を右向きに統一
            if(obj.lineCoords.tl.x < obj.lineCoords.br.x){
                rect = [fbPoint1, fbPoint2] ;
            } 
            else{
                rect = [fbPoint2, fbPoint1] ;
            }
            t_horizon.push(rect);
            // 合成できる線をまとめる
            collectionVector(t_horizon);
        }
        // 縦線の場合
        if(kind == 2){
            // 描画方向を上向きに統一
            if(obj.lineCoords.tl.y < obj.lineCoords.br.y){
                rect = [fbPoint1, fbPoint2] ;
            } 
            else{
                rect = [fbPoint2, fbPoint1] ;
            }
            t_vertical.push(rect);
            // 合成できる線をまとめる
            collectionVector(t_vertical);
        }
        // 右上がりの場合
        if(kind == 3){
            // 右上がりの場合のみ、座標の取得ポイントが異なる(bottom-left, top-right)。
            fbPoint1 = near_point(obj.lineCoords.bl.x, obj.lineCoords.bl.y, true);
            fbPoint2 = near_point(obj.lineCoords.tr.x, obj.lineCoords.tr.y, true);

            // 下から上方向に統一
            if(obj.lineCoords.bl.y < obj.lineCoords.tr.y){
                rect = [fbPoint1, fbPoint2] ;
            } 
            else{
                rect = [fbPoint2, fbPoint1] ;
            }
            t_upRight.push(rect);
            
            collectionVector(t_upRight);
        }
        // 右下がりの場合
        if(kind == 4){
            // 上から下方向に統一
            if(obj.lineCoords.tl.x < obj.lineCoords.br.x){
                rect = [fbPoint1, fbPoint2] ;
            } 
            else{
                rect = [fbPoint2, fbPoint1] ;
            }
            t_downRight.push(rect);
            // 合成できる線をまとめる
            collectionVector(t_downRight);
        }
    });

    // 生徒の描画データと照合しやすいようにソートしておく
    t_horizon.sort();
    t_vertical.sort();
    t_upRight.sort();
    t_downRight.sort();

    // console.log(t_horizon.length);
    // console.log(t_vertical.length);
    // console.log(t_upRight.length);
    // console.log(t_downRight.length);
}

function vectorJudge(line){
    let kind ;
    if( line.x1 < line.x2 && line.y1 > line.y2 ){
        //console.log("ベクトルは右上り");
        kind = 3;
    }
    else if( line.x1 < line.x2 && line.y1 < line.y2){
        //console.log("ベクトルは右下がり");
        kind = 4;
    }
    else if( line.x1 > line.x2 && line.y1 > line.y2){
        //console.log("ベクトルは右下がり");
        kind = 4;
    }
    else if( line.x1 > line.x2 && line.y1 < line.y2){
        //console.log("ベクトルは右上り");
        kind = 3;
    }
    else if(line.x1 == line.x2){
        //console.log("ベクトルは上下");
        kind = 2;
    }
    else{
        //console.log("ベクトルは左右");
        kind = 1;
    }
    return kind;
}

// 同じ線分(縦、横、右下がり、右上がり)をまとめる関数
// 始点、終点が同じであれば、線を合成する。
// reduceで実装できるかもしれないが、可読性がより落ちるか。
function collectionVector(collectionArry){
    let removeIndex ;
    let updateRect;
    // 全ての線分で合成できなくなるまでループ
    while(1){

        removeIndex = -1;
        for(let i = 0 ; i < collectionArry.length - 1; i++ ){
            const baseRect = collectionArry[i];
            let j;
            for( j = i + 1 ; j < collectionArry.length ; j++ ){
                const subRect = collectionArry[j];
                // 線１の終点と線２の始点とが同じ座標であれば合成し、合成前の２本の線を消去
                // ここでのeqはfabric.jsのPointオブジェクトの比較メソッド
                if(baseRect[1].eq(subRect[0])){
                    updateRect = [baseRect[0], subRect[1]];
                    removeIndex = [i, j];
                    break;
                }
                // 線１の始点と線２の終点とが同じ座標であれば合成し、合成前の２本の線を消去
                // ここでのeqはfabric.jsのPointオブジェクトの比較メソッド
                else if(baseRect[0].eq(subRect[1])){
                    updateRect = [subRect[0], baseRect[1]];
                    removeIndex = [i, j];
                    break;
                }
            }
            if(j != collectionArry.length) break;
        }
        
        if(removeIndex != -1){
            // 2本の線を消去し、格納順の後者から順に消去し、合成した線を追加
            collectionArry.splice(removeIndex[1], 1);
            collectionArry.splice(removeIndex[0], 1);
            collectionArry.push(updateRect);
        }
        else{
            break;
        }
        
    }
}