import { imageSizing, fontSizeFittingByHeight } from "../training_common";
import choiceFitting from "./image_common";
export default function no2Fitting()
{
    // 画像の拡大
    imageSizing($(".image-situation-table img"), $(".situation-border").width(), $(".situation-area").height() * 0.9);
    
    let fontsize = 40;
    fontsize = fontSizeFittingByHeight($(".situation-border"), $(".situation-area").height(), 1, fontsize)
    /*
    while(1){
        $(".situation-border").css("font-size", fontsize);
        if($(".situation-border").height() < $(".situation-area").height()){
            //console.log("fontsize5: " + fontsize);
            break;
        }
        fontsize -= 1;
    }
    */

    // 選択肢枠の調整
    choiceFitting(fontsize);


}