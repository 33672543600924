import {modalShow} from '../training_common'
import {countDown} from './memory_common';

export default function shortTimer(){
    
    const disappearSecond = parseInt($("#disappear-second").val());

    // スタートボタンをクリック
    $(document).on('click',"#memory-start", function(){

        // 選択画像を一致判定するために、画像srcの一致を比較する。
        const image_sources = [];
        // 見本画像の表示及び、画像srcを配列に格納
        $(".c-modal").removeClass("c-hidden");
        //('>'は直下というセレクタ )
        $(".target >").each(function(){
            image_sources.push($(this).attr("src"));
        });
        //console.log(image_sources);
        // スタートボタンを非表示
        $(this).toggle();
        countDown(disappearSecond * 1000);
        
        // 指定秒数後に、見本画像の非表示と、選択肢画像の表示
        setTimeout(function(){
            $(".c-modal").addClass("c-hidden");
            $(".memory-main-contents").toggle();
            answerClick(image_sources);
        }, disappearSecond * 1000);

    });
}


function answerClick(image_sources){

    const targetCount = parseInt($("#target-count").val());
    
    // 選択肢画像をクリックしたときの動作
    $(document).on('click', '.choices', function(){
        
        // 見本画像以外をクリックした場合はアラート表示('>'は直下というセレクタ )
        if(image_sources.indexOf($(">", this).attr("src")) < 0){
            modalShow("不合格", "不正");
        }
        // 見本画像とおなじ画像をクリックした際は、画像下にチェックマークをつける。
        else{
            $(".check", this).css("visibility", "visible");
            // 全ての見本画像を見つけたか判定
            var check_count = 0 ;
            $(".check").each(function(){
                if($(this).css("visibility") == "visible") check_count++;
            });
            if(check_count == targetCount) {
                // 全ての画像を見つけたら、モーダルを表情して、問題カウンターをインクリメントする
                modalShow("合格", "正解");
                // clickイベントを削除
                $(document).off('click', '.choices');
            }
        }
    });
}
